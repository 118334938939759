.people-table {
  box-shadow: 0.629px -0.777px 35px 0px rgba(0, 0, 0, 0.16);
  background-color: rgb(255, 255, 255);
  border-radius: 4px;
  padding: 20px;
}

.people-table table td:first-child,
.people-table table th:first-child {
  width: 140px;
}

.people-table table td:nth-child(2),
.people-table table th:nth-child(2) {
  width: auto;
}

.people-table table td:nth-child(3),
.people-table table th:nth-child(3) {
  width: 100px;
}

.people-table table td:nth-child(4),
.people-table table th:nth-child(4) {
  width: 100px;
}

.people-table table td:nth-child(5),
.people-table table th:nth-child(5) {
  width: 100px;
}

.people-table table td:nth-child(6),
.people-table table th:nth-child(6) {
  width: auto;
}

.people-table table td:nth-child(7),
.people-table table th:nth-child(7) {
  width: 80px;
}

.people-table table td:nth-child(8),
.people-table table th:nth-child(8) {
  width: auto;
}

.people-table table td:nth-child(9),
.people-table table th:nth-child(9) {
  width: auto;
}

.people-table table td:nth-child(10),
.people-table table th:nth-child(10) {
  width: auto;
}

.people-table table td:nth-child(11),
.people-table table th:nth-child(11) {
  width: auto;
}

.people-table table td:last-child,
.people-table table th:last-child {
  width: 100px;
}


.people-table-header {
  display: flex;
  justify-content: space-between;
  padding-bottom: 12px;
}

.people-table table th{
  font-family: var(--font-medium);
  font-size: 12px;
}

.people-table-header-title {
  color: #5b626b;
  font-family: var(--font-medium);
  font-size: 16px;
}

.people-table-header-map {
  color: var(--mainColor);
  font-size: 14px;
  cursor: pointer;
}

.people-table-header-search {
  color: #b7b8b9;
  font-size: 12px;
  font-family: var(--font-medium);
  text-align: right;
}

.people-table-comment-text p {
  color: var(--mainColor);
  cursor: pointer;
}

.people-table-comment-form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.people-table-comment-form-btns {
  display: flex;
  align-items: center;
}
.people-table-comment-form-btns._widget {
  justify-content: center;
}

.people-table-comment-form-btns p {
  margin-right: 8px;
}

.people-table-comment-form-btns span {
  color: #dc3545;
  font-size: 12px;
  cursor: pointer;
}

.people-table-comment-text {
  font-size: 12px;
  margin-bottom: 4px;
}

.people-table-comment-form textarea {
  display: block;
  width: 100%;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  padding: .375rem .75rem;
  margin-bottom: 5px;
  resize: vertical ;
}

.people-table-comment-form p,
.people-table-comment-control p{
  color: var(--mainColor);
  font-size: 12px;
  cursor: pointer;
  margin-bottom: 0;
}

.people-table-comment-form span {
  color: #dc3545;
  font-size: 12px;
  cursor: pointer;
  line-height: 1.7;
}

.people-table-capacity span:first-child {
  font-weight: bold;
  color: #419c5f;
}

.people-table-capacity span:last-child {
  color: #000;
}

.people-table-footer .btn {
  margin-right: 10px;
  margin-bottom: 20px;
}

.people-table table {
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;
  border-top: 2px solid var(--mainColor);
  margin-bottom: 20px;
}

.people-table tbody tr.tr-first:nth-child(odd){
  background-color: #f8f9fa
}

.people-table table th,
.people-table table td{
  padding: 14px 10px;
  font-size: 12px;
  color: #212224;
  line-height: 16px;
  word-break: break-word;
}

.people-table table img {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  margin-right: 10px;
}
.people-table table img.dropdown {
  border-radius: 50%;
  width: 16px;
  height: 16px;
  margin-right: 8px;
}

.people-table-history table th:first-child,
.people-table-history table td:first-child {
  width: 50px;
}

.people-table .custom-control {
  width: 0;
  margin: 0;
  padding-left: 30px;
}

.people-table .custom-control label {
  cursor: pointer;
}

.people-table-btn-group-single-delete {
  width: 48px !important;
}

.people-table-status-active,
.people-table-status-disabled,
.people-table-status-alert,
.people-table-status-assigned,
.people-table-status-dispatched,
.people-table-status-picked{
  padding: 6px 7px 4px;
  width: 80px;
  text-align: center;
  text-transform: uppercase;
  border-radius: 3px;
  color: #fff;
  display: inline-block;
  font-size: 12px;
  line-height: 14px;
  font-family: var(--font-myriadSemibold-regular);
}

.people-table-status-active {
  background-color: #58db83;
}

.people-table-status-disabled {
  background-color: #6c757d;
}

.people-table-status-alert {
  background-color: #f5b225;
}

.people-table-status-picked {
  background-color: #6c757d;
}

.people-table-status-dispatched {
  background-color: #B24AF1;
}

.people-table-status-assigned {
  background-color: var(--mainColor);
}

.people-table-history table td:nth-child(1),
.people-table-history table th:nth-child(1) {
  width: 150px;
}

.people-table-history table td:first-child,
.people-table-history table th:first-child {
  width: 30px;
}

.people-table-history table td:nth-child(2),
.people-table-history table th:nth-child(2) {
  width: 110px;
}

.people-table-history table td:nth-child(3),
.people-table-history table th:nth-child(3) {
  width: 100px;
}

.people-table-history table td:nth-child(4),
.people-table-history table th:nth-child(4) {
  width: 100px;
}

.people-table-history table td:nth-child(5),
.people-table-history table th:nth-child(5) {
  width: auto;
}

.people-table-history table td:nth-child(6),
.people-table-history table th:nth-child(6) {
  width: 90px;
}

.people-table-history table td:nth-child(7),
.people-table-history table th:nth-child(7) {
  width: auto;
}

.people-table-history table td:nth-child(8),
.people-table-history table th:nth-child(8) {
  width: auto;
}

.people-table-history table td:nth-child(9),
.people-table-history table th:nth-child(9) {
  width: auto;
}

.people-table-history table td:nth-child(10),
.people-table-history table th:nth-child(10) {
  width: auto;
}

.people-table-history table td:last-child,
.people-table-history table th:last-child {
  width: 80px;
}

.people-table-transporter table td:first-child,
.people-table-transporter table th:first-child {
  width: auto;
}

.people-table-transporter table td:nth-child(2),
.people-table-transporter table th:nth-child(2) {
  width: 120px;
}

.people-table-transporter table td:nth-child(3),
.people-table-transporter table th:nth-child(3) {
  width: auto;
}

.people-table-transporter table td:nth-child(4),
.people-table-transporter table th:nth-child(4) {
  width: auto;
}

.people-table-transporter table td:nth-child(5),
.people-table-transporter table th:nth-child(5) {
  width: 250px;
}

.people-table-transporter table td:nth-child(6),
.people-table-transporter table th:nth-child(6) {
  width: 80px;
}

.people-table-transporter table td:nth-child(7),
.people-table-transporter table th:nth-child(7) {
  width: 100px;
}

/* .people-table-transporter table td:nth-child(3),
.people-table-transporter table th:nth-child(3),
.people-table-transporter table td:nth-child(4),
.people-table-transporter table th:nth-child(4) {
  max-width: 150px;
}

.people-table-transporter table td:nth-child(5),
.people-table-transporter table th:nth-child(5) {
  max-width: 150px;
}

.people-table-transporter table td:nth-child(6),
.people-table-transporter table th:nth-child(6) {
  max-width: 80px;
}

.people-table-transporter table td:nth-child(7),
.people-table-transporter table th:nth-child(7) {
  max-width: 100px;
} */

.people-table-transporter table td:last-child,
.people-table-transporter table th:last-child{
  width: 80px;
}


.people-table-btn-group-control {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.people-table-btn-group-control .people-table-btn-group-toggle {
  margin-right: 20px;
  width: 90px;
}

.people-table-btn-group-control .people-table-btn-group-toggle input:checked ~ .people-table-btn-group-toggle-active {
  display: block;
  padding-left: 35px;
}

.people-table-btn-group-control .people-table-btn-group-toggle input:checked ~ .people-table-btn-group-toggle-notActive {
  display: none;
}

.people-table-btn-group-control .people-table-btn-group-toggle input ~ .people-table-btn-group-toggle-active {
  display: none;
}

.people-table-btn-group-control .people-table-btn-group-toggle input ~ .people-table-btn-group-toggle-notActive {
  display: block;
  padding-left: 8px;
}

.people-table-btn-group-control .people-table-btn-group-toggle-notActive,
.people-table-btn-group-control .people-table-btn-group-toggle-active {
  font-size: 12px;
  border: 1px solid var(--status-color-dark);
  background-color: #fafafa;
  border-radius: 30px;
  position: relative;
  z-index: 1;
  width: 100%;
  display: block;
  height: 26px;
  line-height: 24px;
  font-family: var(--font-myriadSemibold-regular);
  color: var(--status-color-dark);
  margin-bottom: 0;
  transition: all linear 0.25s

}


.people-table-btn-group-control .people-table-btn-group-toggle label {
  position: relative;
  z-index: 1;
  width: 100%;
  display: block;
  margin-bottom: 0;
  height: 26px;
  cursor: pointer;
}

.people-table-btn-group-control .people-table-btn-group-toggle .people-table-btn-group-toggle-icon {
  position: absolute;
  top: 3px;
  right: 3px;
  width: 20px;
  height: 20px;
  background-color: var(--status-color-dark);
  border-radius: 50%;
  z-index: 5;
  transition: all linear 0.25s
}

.people-table-btn-group-control .people-table-btn-group-toggle input {
  display: none;
}

.people-table-btn-group-control .people-table-btn-group-toggle input:checked ~ .people-table-btn-group-toggle-icon {
  background-color: #fff;
  transform: translateX(-64px);
}

.people-table-btn-group-control .people-table-btn-group-toggle input:checked ~ .people-table-btn-group-toggle-active {
  border-color: var(--status-color-green);
  background-color: var(--status-color-green);
  color: #fff;
}

.people-table-btn-group-control .people-table-btn-group-icon {
  border-radius: 3px;
  border: 1px solid var(--mainColor);
}

.people-table-comment-text-red {
  color: #dc3545;
}

.tr-additional-info {
  border-bottom: none !important;
}

.hiddenTrRow, .hiddenTdRow {
  line-height: 0 !important;
  padding: 0 !important;
  opacity: 0 !important;
}
.openTdRow {
  padding: 0 !important;
}
.openRow {
  opacity: 1;
  transition: all 0.5s;
  padding-left: 20px;
  padding-bottom: 16px;
}
.carRow {
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 24px;
  padding-right: 12px
}

.load_details_header {
  border-radius: 3px;
  border-width: 1px;
  border-color: dimgray;
  border-style: solid;
  text-align: center;
  padding: 7px;
  background-color: whitesmoke;
  color: black;
  margin-right: 10px;
  margin-bottom: 10px;
}

.load_details-btn {
  border-radius: 3px;
  border-width: 1px;
  border-style: solid;
  text-align: center;
  padding: 8px 12px 4px 12px;
  background: var(--mainColor);
  border-color: var(--mainColor);
  color: #fff;
  margin-right: 10px;
  margin-bottom: 10px;
  font-family: var(--font-myriadSemibold-regular);
  font-size: 14px;
}

.load_details-btn:hover {
  background: var(--mainColorHover);
  border-color: var(--mainColorHover);
  color: #fff;
  cursor: pointer;
}

.load_details-btn:active,
.load_details-btn:focus {
  background: var(--mainColorActive) !important;
  border-color: var(--mainColorActive) !important;
  color: #fff !important;
}

.car_detail_box {
  display: flex;
  flex-wrap: wrap;
}

.load_details_header:hover {
  background-color: gainsboro;
  cursor: pointer;
}

div.rowContainer {
  max-width: 100%;
  width: 100%;
  align-items: left;
  vertical-align: top;
  display: inline-block;
}

div.rowOuter {
  display: inline-block;
  padding-left: 0%;
  padding-right: 40px;
  padding-top: 10px;
  vertical-align: top;
}

div.rowOuter ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

div.rowOuter li:hover {
  cursor: pointer;
  /* text-shadow: 0 0 15px #c1c1c1, 0 0 10px #c1c1c1; */
  text-decoration: underline;
}

div.rowInner {
  display: inline-block;
  padding-left: 0%;
  padding-right: 40px;
  vertical-align: top;
  padding-top: 10px;
}

div.rowInner ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

div.rowInner li:hover {
  cursor: pointer;
  /* text-shadow: 0 0 15px #c1c1c1, 0 0 10px #c1c1c1; */
  text-decoration: underline;
}

div.rowLast {
  display: inline-block;
  padding-left: 0%;
  padding-right: 40px;
  vertical-align: top;
  padding-top: 10px;
}

div.rowLast ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

div.rowLast li:hover {
  cursor: pointer;
  /* text-shadow: 0 0 15px #c1c1c1, 0 0 10px #c1c1c1; */
  text-decoration: underline;
}

.rowTitle {
  font-weight: bold;
  color: lightslategray; 
  padding-left: 10px
}

.rowSubtitle {
  font-weight: bold;
  color: lightslategray; 
  cursor: auto !important;
  text-decoration: none !important;
  text-shadow: none !important;
}


.hiddenRow {
  width: 100%;
  height: 0 !important;
  opacity: 0 !important;
  transition: all 0.5s;
  line-height: 0 !important;
}

.td-is-open-load:hover {
  cursor: pointer;
}

.td-no-padding {
  padding: 0 !important;
}
.table-no-padding, .table-total-sum {
  border-top: 0 !important;
  margin-bottom: 0 !important;
}
.table-total-sum {
  margin-top: -24px;
  margin-bottom: 5px !important;
}
.people-table table.table-total-sum tr {
  border: none !important;
}
.custom-form-control-select-drivers {
  width: 200px;
  margin: 0 auto;
  margin-top: 0px;
}
.custom-form-control-select-drivers._filters {
  height: 26px;
  font-size: 12px;
}

.custom-form-control-select-drivers._filters .css-bg1rzq-control,
.custom-form-control-select-drivers._filters .css-1szy77t-control{
  min-height: 26px;
  height: 26px;
}

.custom-form-control-select-drivers._filters .css-1wy0on6,
.custom-form-control-select-drivers._filters .css-1hwfws3,
.custom-form-control-select-drivers._filters .css-1g6gooi
{
  height: 26px;
  line-height: 16px;
}

.filter-by-drivers-text {

}
.form-control-datepicker-range {
  display: block;
  height: calc(2.25rem + 2px);
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.form-control-datepicker-range._filters {
  display: block;
  height: 26px;
  padding: .375rem .75rem;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.datepicker-item {
  margin-right: 30px;
}

.people-table-header-filter {
  color: #5b626b;
  font-family: var(--font-medium);
  font-size: 12px;
}

.loads-table-header {
  list-style: none;
  display: flex;
  padding: 0;
  margin-bottom: 0;

}
.loads-table-header.header-first > li:nth-child(1) {
  margin-left: 10px;
}

.loads-table-header li {
  margin-right: 30px;
}

.loads-table-header.header-second li {
  margin-right: 15px;
}

.loads-table-header .form-control-datepicker-range {
  max-width: 120px;
}
.react-datepicker-popper {
  z-index: 10;
}
.dates-clean-up {
  position: absolute;
  margin: 8px 0 0 4px;
  padding-left: 120px;
}
.dates-clean-up:hover {
  color: var(--mainColor);
  cursor: pointer;
}
.fa-rotate-90 {
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  transform: rotate(-90deg);
  transition: all .3s ease-in-out, all .3s ease-in-out;
}

.fa-rotate-0 {
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  transition: all .3s ease-in-out, all .3s ease-in-out;
}
.exportCSV:hover {
  cursor: pointer;
}
.exportHistoryCSV {
  justify-content: flex-end;
  display: flex;
  flex: 1;
  align-items: flex-end;
}


.people-table-btn-group {
  display: flex;
  justify-content: center;
  border: 1px solid var(--mainColor);
  border-radius: 3px;
  /* width: 80px; */
  width: fit-content;
  margin: 0 auto;
}

.send-invite-box {
  display: flex;
  justify-content: center;
  width: 80px;
  margin: 0 auto;
}

.people-table-btn-group-single {
  width: 26px;
}

.people-table-btn-group-icon:not(:last-child) {
  border-right: 1px solid var(--mainColor);
}

.trash-btn {
  border: 1px solid var(--mainColor);
}

.action-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.people-table-btn-group-icon {
  padding: 5px 6px;
  color: var(--mainColor);
  text-align: center;
  position: relative;
  z-index: 1;
  cursor: pointer;
}
.people-table-btn-group-icon:hover {
  color: var(--mainColorActive);
}

.people-table-btn-edit {
  font-size: 14px;
  color: #6e8195;
  cursor: pointer;
}

.people-table-btn-delete {
  color: #d33232;
  font-size: 14px;
  cursor: pointer;
}

.people-table-btn-edit-lock,
.people-table-btn-delete-lock {
  color: #c1c1c1;
  cursor: inherit;
}

.people-table-btn-edit-lock:hover,
.people-table-btn-delete-lock:hover {
  color: #c1c1c1;
}

.modal-body-delete {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-bottom: 60px;
}

.modal-body-delete button {
  margin: 0 80px;
  font-family: var(--font-myriadSemibold-regular);
}

.modal-body-delete-text {
  text-align: center;
  line-height: 36px;
  font-size: 28px;
  width: 100%;
  padding: 40px 0 20px 0;
}


.custom-pagination .page-item [aria-disabled="true"] {
  color: #ccc;
}


.people-table table td.data-warning {
  color: #dc3545;
}

.load-table-header {
  list-style: none;
  display: flex;
  padding: 0;
  margin-bottom: 0;
  width: 100%;
}

.load-table-header.header-first > li:nth-child(2) {
  margin-left: 30px;
}

.load-table-header li {
  margin-right: 30px;
}
.load-table-header li.exportCSV {
  margin-right: 0;
}

.load-table-header.header-second li {
  margin-right: 15px;
}
.delayed_checkbox {
  margin-right: 6px;
  margin-top: 6px;
}

.exportCSV {
  justify-content: flex-end;
  display: flex;
  flex: 1;
}
.exportCSV:hover {
  cursor: pointer;
}

.export-csv-image {
  height: 26px;
  margin-top: -3px;
}

.action-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border: 1px solid #5175A1;
}

.action-button.check {
  color: #7DD78B
}

.action-button.delete {
  color: #db5264
}

.action-button + .action-button {
  margin-left: 15px
}

.order-table {
  background-color: rgba(235, 235, 235, 0.4);
  border-top: none !important;
}

.table-border {
  border-bottom: 1px solid #5175A1;
}

.flex-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.car-counter {
  font-size: 11px;
  font-weight: 500;
  background: #B24AF1;
  border-radius: 50%;
  margin-left: 5px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 20px;
  max-width: 20px;
  min-height: 20px;
  max-height: 20px;
}

.action-icon {
  margin-left: 10px;
  cursor: pointer;
}

.order-table .table-head {
  display: flex;
  width: 100%;
  border-bottom: 1px solid #5175A1;
  padding: 10px 0 20px 20px;
}

.table-head div, .people-table-car-info-row div  {
  flex: 1;
  margin-right: 10px;
}

.table-head div {
  font-family: var(--font-medium);
  font-size: 12px;
}

.people-table-car-info-row {
  width: 100%;
  display: flex;
  padding: 6px 0 6px 20px;
  align-items: center;
  border-bottom: 1px solid #E7E9EC;
}
.people-table-car-info-row .no-logo {
  width: 42px;
  height: 42px;
  background: #E7E9EC;
  border-radius: 3px;
}

.people-table-car-info-row .logo {
  width: 42px;
  height: auto;
  background: #E7E9EC;
  border-radius: 3px;
}

.people-table-car-info-row .cell {
  color: #59B9DF;
}

.unique-flex {
  flex: 0.7 !important;
}

.current-company {
  flex: 1.5 !important;
}

.people-table-car-info-row .download-link > span {
  color: #59B9DF;
  text-decoration: underline;
  cursor: pointer;
}

.people-table-car-info-row .action-buttons-container {
  display: flex;
}

.action-buttons-container .action-button {
  border: 1px solid #5175A1;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #7DD78B;
  max-width: 25px;
  max-height: 25px;
  margin-right: 17px;
  border-radius: 3px;
  cursor: pointer;
}

.delete-button {
  color: #DB5264 !important;
  margin: 0 !important;
}

.warning {
  color: red !important;
}

.label-text {
  position: relative;
  font-family: var(--font-medium);
  color: #F50E0E;
  display: inline-block;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  top: 2px;
  left: -20px;
  font-style: italic;
  z-index: 1;
}

.load-new-model-header-item {
    color: var(--mainColor);
    /* background-color: #f3f3f3; */
    border-radius: 4px;
    display: inline-block;
    vertical-align: middle;
    border: 1px solid #b9c0c7;
    padding: 5px 45px 5px 15px;
    position: relative;
    z-index: 0;
    font-size: 12px;
    margin-right: 10px;
    margin-bottom: 10px;
    cursor: pointer;
    height: fit-content;
    /* line-height: 2.0; */
  }
  
  .load-new-model-header-item:hover {
    box-shadow: 0 0 3px 0px #c5c5c5;
  }
  
  .load-new-model-header-item span {
    width: 34px;
    height: 29px;
    position: absolute;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    top: 0;
    right: 0;
    border-left: 1px solid #b9c0c7;
    transition: all linear 0.2s;
    cursor: pointer;
  }
  
  .load-new-model-header-item span:hover {
    background-color: #dedfe0;
  }
  
  .load-new-model-header-item span:before,
  .load-new-model-header-item span:after {
    content: '';
    width: 14px;
    height: 1px;
    background-color: var(--mainColor);
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    margin: 0 auto;
  }
  
  .load-new-model-header-item span:before {
    transform: rotate(-45deg);
  }
  
  .load-new-model-header-item span:after {
    transform: rotate(45deg);
  }
  
  .load-new-model-header-item-active {
    background-color: var(--mainColor);
    color: #fff;
  }
  
  .load-new-model-header-item-active span:before,
  .load-new-model-header-item-active span:after {
    background-color: #fff;
  }
  
  .load-new-model-header-item-active span:hover {
    background-color: #28588c;
  ;
  }
  
  
  
  
  .load-new-file-header-item {
    color: var(--mainColor);
    background-color: #f3f3f3;
    border-radius: 4px;
    display: inline-block;
    vertical-align: middle;
    /* border: 1px solid #b9c0c7; */
    padding: 2px 30px 3px 15px;
    position: relative;
    z-index: 0;
    font-size: 12px;
    font-weight: 600;
    margin-right: 10px;
    margin-bottom: 10px;
    cursor: pointer;
    height: fit-content;
    /* line-height: 2.0; */
  }
  
  .load-new-file-header-item:hover {
    box-shadow: 0px 0px 3px 0px #c5c5c5;
  }
  
  .load-new-file-header-item span {
    --mainColor: red;
    width: 29px;
    height: 100%;
    position: absolute;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    top: 0;
    right: 0;
    /* border-left: 1px solid #b9c0c7; */
    transition: all linear 0.2s;
    cursor: pointer;
  }
  
  .load-new-file-header-item span:hover {
    background-color: #dedfe0;
  }
  
  .load-new-file-header-item span:before,
  .load-new-file-header-item span:after {
    content: '';
    width: 14px;
    height: 1px;
    background-color: var(--mainColor);
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    margin: 0 auto;
  }
  
  .load-new-file-header-item span:before {
    transform: rotate(-45deg);
  }
  
  .load-new-file-header-item span:after {
    transform: rotate(45deg);
  }
  
  .load-new-file-header-item-active {
    background-color: var(--mainColor);
    color: #fff;
  }
  
  .load-new-file-header-item-active span:before,
  .load-new-file-header-item-active span:after {
    background-color: #fff;
  }
  
  .load-new-file-header-item-active span:hover {
    background-color: #28588c;
  ;
  }
  
:root {
  --mainColor: #4875a5;
  --mainColorHover: #3b73af;
  --mainColorActive: #2b5582;

  --inputBackgroundColor: #f5f5f5;

  --sidebarBackground: #2b3a4a;
  --sidebarBackgroundHover: #263442;

  --color-blue: #4875a5;

  --cart-background-color-1: #5858b1;
  --cart-background-color-2: #4875a5;
  --cart-background-color-3: #28bbe3;

  --status-color-orange: #f5b225;
  --status-color-green: #58db83;
  --status-color-blue: #28bbe3;
  --status-color-dark: #6e8195;

  --font-bold:'Poppins-Bold',Arial, "Helvetica Neue", Helvetica, sans-serif;
  --font-semibold:'Poppins-SemiBold',Arial, "Helvetica Neue", Helvetica, sans-serif;
  --font-regular:'Poppins-Regular',Arial, "Helvetica Neue", Helvetica, sans-serif;
  --font-medium:'Poppins-medium',Arial, "Helvetica Neue", Helvetica, sans-serif;
  --font-myriad-regular: 'MyriadPro-Regular',Arial, "Helvetica Neue", Helvetica, sans-serif;
  --font-myriadSemibold-regular: 'MyriadPro-Semibold',Arial, "Helvetica Neue", Helvetica, sans-serif;

  --font-openSans-bold: 'MyriadPro-Semibold',Arial, "Helvetica Neue", Helvetica, sans-serif;
}

html, body {
  min-height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  font-size: 16px;
  line-height: 24px;
  font-family: var(--font-regular);
  overflow: inherit;
}

#root {
  background-color: #ebebeb;
}

.custom-btn {
  background: var(--mainColor);
  border-color: var(--mainColor);
  color: #fff;
  font-family: var(--font-myriadSemibold-regular);
}

.custom-btn:hover {
  background: var(--mainColorHover);
  border-color: var(--mainColorHover);
  color: #fff;
}

.custom-btn:active,
.custom-btn:focus {
  background: var(--mainColorActive) !important;
  border-color: var(--mainColorActive) !important;
  color: #fff !important;
}

.custom-btn-outline {
  background-color: #fff;
  border: 1px solid #ebebeb;
  color: #dc3545;
}

.custom-btn-outline:hover {
  border-color: #dc3545;
  color: #dc3545;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background: var(--mainColor);
  border-color: var(--mainColor);
}

.custom-checkbox-title {
  font-size: 13px;
  color: #212224;
}

.custom-btn-blocked,
.custom-btn-blocked:hover,
.custom-btn-blocked:focus,
.custom-btn-blocked:active {
  background-color: #6c757d !important;
  border-color: #6c757d !important;
}

a {
  color: var(--mainColor)
}

h1, h2, h3, h4, h5, h6 {
  font-family: var(--font-medium);
  font-weight: normal;
}

html,
.content-page {
  background-color: #ebebeb;
}

.form-control:focus {
  border-color: var(--mainColor)
}

.errorMsg {
  font-size: 12px;
  color: #dc3545;
  margin-bottom: 10px;
  display: block;
}

.breadcrumb li,
.breadcrumb a {
  font-size: 12px;
}

.form-control.is-invalid, .was-validated .form-control:invalid {
  background-image: none;
}

.custom-alert {
  color: #fff;
  background-color: var(--mainColor);
  padding: 5px 15px;
  border-radius: 3px;
}

.wrapper-page {
  max-width: 555px;
}


.page-title-box .page-title {
  font-weight: 500;
}


.breadcrumb .breadcrumb-item {
  font-family: var(--font-regular);
  font-size: 12px;
}

.custom-card-body h5 {
  font-size: 18px;
}


.card-body-title {
  font-size: 18px;
}

.btn-secondary {
  font-family: var(--font-myriadSemibold-regular);
}

.hide {
  display: none;
}

.noteitem {
  font-family: var(--font-regular);
  font-size: 12px;
  padding: 4px;
  color: #6c757d;
}

.custom-pie-chart button{
  display: none !important;
}

.custom-pie-chart {
  position: relative;
}

.custom-pie-chart:after {
  content: '';
  position: absolute;
  bottom: -5px;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 100%;
  height: 30px;
  background-color: #fff;
}

.custom-card-header  {
  display: flex;
  align-items: center;
}

.custom-card-header  a {
  margin-left: auto;
  font-size: 13px;
  font-family: var(--font-semibold);
}

.custom-card-body {
  display: flex;
  flex-wrap: wrap;
  flex: inherit;
  justify-content: center;
}

.custom-card-body h5 {
  width: 100%;
}

.custom-card-body .custom-pie-chart {
  width: 260px;
}

.custom-card-body .list-inline {
  display: flex;
  flex-direction: column;
}

.custom-card-body .widget-chart li {
  width: 100%;
  display: block;
  text-align: left;
  position: relative;
  padding-left: 50px;
}

.custom-card-body .widget-chart li p {
  font-size: 14px;
  color: #5b626b;
  margin-bottom: 10px;
}

.custom-card-body .widget-chart li:before {
  content: '';
  position: absolute;
  top: 24px;
  left: 25px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
}

.custom-card-body .widget-chart li:nth-child(1):before {
  background: var(--cart-background-color-1);
}

.custom-card-body .widget-chart li:nth-child(3):before {
  background: var(--cart-background-color-2);
}

.custom-card-body .widget-chart li:nth-child(2):before {
  background: var(--cart-background-color-3);
}

.custom-info {
  cursor: pointer;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  font-size: 9px;
  line-height: 12px;
  text-align: center;
  display: inline-block;
  vertical-align: middle;
  background-color: #4875a5;
  color: #fff;
  margin-left: 5px;
}

.custom-card-body .custom-form-control-wrap {
  width: 100%;
  margin-bottom: 100px;
}

.custom-card-body .form-control {
  width: 330px;
  margin: 20px auto;
}

.custom-card-body .table {
  text-align: center;
}

.custom-card-body .table thead th {
  border-bottom: none;
  border-top: none;
  line-height: 16px;
  font-weight: 500;
}

.custom-card-body .table td,
.custom-card-body .table th {
  padding: .55em .65em;
}

.custom-card-body .table img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.custom-form-control-select {
  width: 380px;
  margin: 0 auto;
  margin-top: 20px;
}

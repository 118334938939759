.custom-login {
  border: 1px solid #c2c8d9;
  border-radius: 0;
  padding-top: 28px;
  padding-bottom: 20px;
}

.custom-login form {
  margin-top: 20px;
}

.custom-login-header {
  text-align: center;
  position: relative;
  z-index: 1;
}

.custom-login-header .logo {
  position: static;
  margin: 0 auto;
  width: 91px;
  height: 68px;
  display: block;
  border: none;
  border-radius: 0;
  line-height: 0;
  margin-bottom: 28px;

}

.custom-login-header h4 {
  text-transform: uppercase;
  font-size: 18px;
  line-height: 22px;
  margin: 0;
}

.custom-login-header p {
  font-size: 14px;
}


.custom-login .account-card-content {
  padding: 0px 22px 10px 22px;
}


.custom-login input {
  background-color: var(--inputBackgroundColor);
}

.custom-login .custom-control-label:before {
  background-color: var(--inputBackgroundColor);
  top: 0.15rem;
}

.custom-login  .custom-control-label::after {
  top: 0.15rem;
}

.custom-login-msg {
  font-size: 18px;
  color: var(--mainColor);
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.custom-login input::placeholder {
  font-size: 12px;
  color: #b2b5b9;
}

.custom-login-text {
  font-family: var(--font-myriad-regular);
  font-size: 14px;
}

.custom-login .form-group label {
  font-size: 14px;
}

.custom-login .custom-control.custom-checkbox label {
  font-size: 14px;
}

.custom-login .form-group a {
  font-size: 14px;
}


.custom-login  [type='submit'] {
  font-family: var(--font-myriadSemibold-regular);
  font-size: 14px;
}

.custom-forget-pass .form-group button {
  font-family: var(--font-myriadSemibold-regular);
}

.custom-forget-pass  .form-group label {
  font-size: 14px;
}

.googleMapWrap {
  width: 100%;
  height: 500px;
}

.googleMapWrap > div {
  width: auto;
  height: auto;
  max-width: 100% !important;
  max-height: 100% !important;
  position: relative !important;
}


.custom-control .custom-control-inline {
  margin-right: 0;
}

.custom-login .confirm-email-content {
  text-align: center;
  padding-bottom: 140px;
}

.custom-login .confirm-email-content h4 {
  text-transform: uppercase;
  padding-top: 50px;
  margin-bottom: 30px;
  font-size: 18px;
  font-family: var(--font-medium)
}

.custom-login .confirm-email-content p {
  font-size: 14px;
  padding-left: 30px;
  padding-right: 30px;
}

.custom-pie-chart button{
  display: none !important;
}

.custom-pie-chart {
  position: relative;
}

.custom-pie-chart:after {
  content: '';
  position: absolute;
  bottom: -5px;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 100%;
  height: 30px;
  background-color: #fff;
}

.custom-card-header  {
  display: flex;
  align-items: center;
}

.custom-card-header  a {
  margin-left: auto;
  font-size: 13px;
  font-family: var(--font-semibold);
}

.custom-card-body {
  display: flex;
  flex-wrap: wrap;
  flex: inherit;
  justify-content: center;
}

.custom-card-body h5 {
  width: 100%;
}

.custom-card-body .custom-pie-chart {
  width: 260px;
}

.custom-card-body .list-inline {
  display: flex;
  flex-direction: column;
}

.custom-card-body .widget-chart li {
  width: 100%;
  display: block;
  text-align: left;
  position: relative;
  padding-left: 50px;
}

.custom-card-body .widget-chart li p {
  font-size: 14px;
  color: #5b626b;
  margin-bottom: 10px;
}

.custom-card-body .widget-chart li:before {
  content: '';
  position: absolute;
  top: 24px;
  left: 25px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
}

.custom-card-body .widget-chart li:nth-child(1):before {
  background: var(--cart-background-color-1);
}

.custom-card-body .widget-chart li:nth-child(3):before {
  background: var(--cart-background-color-2);
}

.custom-card-body .widget-chart li:nth-child(2):before {
  background: var(--cart-background-color-3);
}

.custom-info {
  cursor: pointer;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  font-size: 9px;
  line-height: 12px;
  text-align: center;
  display: inline-block;
  vertical-align: middle;
  background-color: #4875a5;
  color: #fff;
  margin-left: 5px;
}

.custom-card-body .custom-form-control-wrap {
  width: 100%;
  margin-bottom: 100px;
}

.custom-card-body .form-control {
  width: 330px;
  margin: 20px auto;
}

.custom-card-body .table {
  text-align: center;
}

.custom-card-body .table thead th {
  border-bottom: none;
  border-top: none;
  line-height: 16px;
  font-weight: 500;
}

.custom-card-body .table td,
.custom-card-body .table th {
  padding: .55em .65em;
}

.custom-card-body .table img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.custom-form-control-select {
  width: 380px;
  margin: 0 auto;
  margin-top: 20px;
}

/*.nav-tabs-custom-loads .nav-item {*/
/*width: 50%;*/
/*text-align: center;*/
/*}*/

/*.custom-chartjs-size-monitor canvas {*/
/*width: 100% !important;*/
/*height: 305px !important;*/
/*padding-left: 80px;*/
/*padding-right: 80px;*/
/*}*/


/*.rv-xy-plot__series--label-text {*/
/*display: none;*/
/*}*/

.rv-xy-plot__grid-lines,
.rv-xy-plot__grid-lines__line{
  display: none;
}

.rv-xy-plot {
  margin: 0 auto;
  margin-top: 20px;
}
.nav.nav-tabs.nav-tabs-custom.nav-tabs-custom-loads .nav-item {
  width: 50%;
  text-align: center;
}


.completedLoadsView {
  width: 500px;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  padding-top: 20px;
}


.completedLoadsViewItem {
  margin: 0 30px;
  position: relative;
  z-index: 1;
  padding-left: 40px;
}

.completedLoadsViewItem:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  width: 20px;
  height: 10px;
  margin-top: -5px;
}

.completedLoadsViewItem--old:before{
  background-color: rgb(18, 147, 154);
}

.completedLoadsViewItem--new:before{
  background-color: rgb(121, 199, 227);
}

/*.completedLoads .rv-xy-plot__inner .rv-xy-plot__series--label{*/
/*transform: translate(0,0) !important;*/
/*}*/


.toolTipBar {
  /* position: absolute; */
  height: auto;
  box-shadow: rgba(0, 0, 0, 0.1) 1px 1px 2px 2px;
  z-index: 1000;
  pointer-events: none;
  border-radius: 0px;
  left: 132px;
  bottom: -72px;
  transition: left 0.1s ease-out 0s, bottom 0.1s ease-out 0s;
}

.toolTipBar .wrap {
  width: auto;
  height: auto;
  min-width: 50px;
  margin: 0px;
  padding: 5px;
  font-family: "Trebuchet MS", Helvetica, sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: 14px;
  color: black;
  text-shadow: rgba(0, 0, 0, 0.1) 1px 1px 1px;
  text-align: left;
  border: 1px solid rgb(40, 187, 227);
  background: rgba(255, 255, 255, 0.9);
  text-indent: 0px;
  white-space: nowrap;
  border-radius: 0px;
  -webkit-user-select: none;
          user-select: none;
}

.table-top-clients td {
  color: #3d4043;
  font-size: 14px;
}

.custom-card-body .table thead th {
  font-size: 12px;
}

.table-top-clients thead th {
  font-family: var(--font-semibold);
  color: #3d4043;
}

.table-top-clients thead th:nth-child(1),
.table-top-clients tbody td:nth-child(1){
  width: 72px;
}

.table-top-clients thead th:nth-child(2),
.table-top-clients tbody td:nth-child(2){
  text-align: left;
}

.table-top-clients thead th:nth-child(3),
.table-top-clients tbody td:nth-child(3){
  width: 160px;
}

.table-top-clients thead th:nth-child(4),
.table-top-clients tbody td:nth-child(4) {
  width: 100px;
}

.nav-tabs-custom {
  justify-content: space-between;
}

.nav-tabs-custom-driver .nav-item {
  width: 50%;
  text-align: center;
}

.nav-tabs-custom > li > a.active {
  color: var(--mainColor) !important
}
.nav-tabs-custom > li > a:after {
  background: var(--mainColor)
}

.nav-tabs-custom > li > a::after {
  height: 3px;
}

.custom-driver-list {
  padding-left: 0;
  list-style: none;
  color: #3d4043;
}

.custom-driver-list-header {
  padding: 10px;
  display: flex;
}

.custom-driver-list-item {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 10px;
  border-bottom: 1px solid #ccc;
  font-size: 14px;
}

.custom-driver-list-item:hover {
  background-color: #f8f9fa;
}

.custom-driver-list-header span {
  text-align: center;
  font-size: 14px;
  color: var(--mainColor);
}

.custom-driver-list-header span:nth-child(1),
.custom-driver-list-item .custom-driver-list-item-img {
  width: 50px;
}

.custom-driver-list-header span:nth-child(2),
.custom-driver-list-item  .custom-driver-list-item-note {
  width: 80px;
  font-size: 14px;
}

.custom-driver-list-header span:nth-child(3),
.custom-driver-list-item  .custom-driver-list-item-name {
  width: calc(100% - 130px - 120px - 280px);
  font-size: 14px;
}

.custom-driver-list-header span:nth-child(4),
.custom-driver-list-item  .custom-driver-list-item-statusWrap {
  width: 120px;
  font-size: 14px;
}

.custom-driver-list-header span:nth-child(5),
.custom-driver-list-item  .custom-driver-list-item-price {
  width: 100px;
  font-size: 14px;
}

.custom-driver-list-header span:nth-child(6),
.custom-driver-list-item .custom-driver-list-item-fee {
  width: 80px;
  font-size: 14px;
}

.custom-driver-list-header span:nth-child(7),
.custom-driver-list-item .custom-driver-list-item-loads {
  width: 100px;
  font-size: 14px;
}

.custom-driver-list-header._footer span:nth-child(1) {
  width: 130px;
}
.custom-driver-list-header._footer span:nth-child(2) {
  width: 0;
}

.custom-driver-list-item-img img {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  display: block;
}

.custom-driver-list-item-name {
  font-size: 16px;
  text-align: center;
}

.custom-driver-list-item-note {
  font-size: 16px;
  text-align: center;
}

.custom-driver-list-item-status {
  position: relative;
  z-index: 1;
  padding-left: 20px;
  text-align: center;
}

.custom-driver-list-item-status:before {
  content: '';
  display: block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 0;
  margin-top: -6px;
}

.custom-driver-list-item-status-active:before{
  background-color: var(--status-color-green);

}
.custom-driver-list-item-status-assigned:before{
  background-color: var(--status-color-orange);

}
.custom-driver-list-item-status-notActive:before{
  background-color: var(--status-color-blue);
}

.custom-driver-list-item-status-deactivated:before {
  background-color: var(--status-color-dark);
}

.custom-driver-list-item-price,
.custom-driver-list-item-loads {
  text-align: center;
}

.custom-driver-list-item-price span,
.custom-driver-list-item-loads span{
  display: block;
  color: #5b5f66;
}

.custom-driver-list-item-loads {
  text-align: center;
}

.custom-pagination {
  margin: 0 auto;
  display: flex;
  justify-content: center;
}

.custom-pagination .page-item.active .page-link {
  background-color: var(--mainColor) !important;
  color: #fff;
}

.custom-pagination .page-item .page-link{
  color: var(--mainColor);

}


@media(min-width: 1600px) {

  .custom-driver-list-header span {
    font-size: 16px;
  }

  .custom-driver-list-header span:nth-child(1),
  .custom-driver-list-item .custom-driver-list-item-img {
    width: 50px;
  }

  .custom-driver-list-header span:nth-child(2),
  .custom-driver-list-item  .custom-driver-list-item-note {
    width: 100px;
    margin-left: 16px;
  }

  .custom-driver-list-header span:nth-child(3),
  .custom-driver-list-item  .custom-driver-list-item-name {
    width: calc(100% - 50px - 100px - 120px - 110px - 220px);
  }

  .custom-driver-list-header span:nth-child(4),
  .custom-driver-list-item  .custom-driver-list-item-statusWrap {
    width: 120px;
  }

  .custom-driver-list-header span:nth-child(5),
  .custom-driver-list-item  .custom-driver-list-item-price {
    width: 110px;
  }

  .custom-driver-list-header span:nth-child(6),
  .custom-driver-list-item .custom-driver-list-item-fee {
    width: 110px;
  }

  .custom-driver-list-header span:nth-child(7),
  .custom-driver-list-item .custom-driver-list-item-loads {
    width: 110px;
  }

  .custom-driver-list-header._footer span:nth-child(1) {
    width: 150px;
  }
  .custom-driver-list-header._footer span:nth-child(2) {
    width: 0;
  }
}

.people-table-comment-text .feeStyle {
  margin-left: 6px;
  font-size: 12px;
}
.custom-driver-list-item-fee .people-table-comment-control {
  text-align: center;
}

.people-table-header-filter._mydrivers {
  margin-left: 12px;
}

.arrowed {
  position: relative;
  margin-top: 4px;
}
.arrowed div {
  height: 0; width: 0;
  border: 8px solid;
  border-color: #c7c7c7 #c7c7c7 transparent transparent;
}
.arrowed .active {
  border-color: var(--mainColor) var(--mainColor) transparent transparent;
}

.arrow-next {
  transform: rotate(225deg);
  float: right;
}
.arrow-next:hover {
  cursor: pointer;
}
.arrow-prev {
  transform: rotate(45deg);
  float: left;
}
.arrow-prev:hover {
  cursor: pointer;
}

.nav-arrow {
  width: 29%;
}
.nav-center {
  width: 42%;
  text-align: center;
}


.main-content-header {
  display: flex;
  justify-content: space-between;
}

.main-content-header-loads {
  display: flex;
}

.main-content-header-left-dashboard {
  display: flex;
  align-items: center;
}

.main-content-header-left-dashboard .custom-btn{
  margin-left: 20px;
  margin-top: 4px;
  padding-left: 30px;
  padding-right: 30px;
}

.main-content-header-statistic {
  list-style: none;
  display: flex;
  padding: 0;
  margin-bottom: 0;
}

.main-content-header-left-dashboard {
  display: flex;
  align-items: center;
}

.main-content-header-left-dashboard .custom-btn{
  margin-left: 20px;
  margin-top: 4px;
  padding-left: 30px;
  padding-right: 30px;
}

.main-content-header-statistic-item{
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-template-areas: "value title" "value sup";
  margin-right: 30px;
}

.main-content-header-statistic-item-value {
  grid-area: value;
  font-size: 48px;
  line-height: 48px;
  color: var(--color-blue);
  text-align: right;
  padding-right: 10px;
  border-right: 1px solid var(--color-blue);
}

.main-content-header-statistic-item-title {
  grid-area: title;
  font-size: 18px;
  line-height: 22px;
  color: #5b626b;
  padding-left: 10px;

}

.main-content-header-title {
  font-family: var(--font-medium);
}

.main-content-header-statistic-item-sup {
  grid-area: sup;
  font-size: 12px;
  line-height: 14px;
  color: var(--color-blue);
  padding-left: 10px;
}

.main-content-header-search {
  width: 630px;
}

.main-content-header-search-add {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 800px;
}

.main-content-header-search-add .btn{
  flex: 0 0 auto;
  margin-left: 15px;
  padding-left: 30px;
  padding-right: 30px;
}

.main-content-header-search-stat {
  padding-bottom: 5px;
  font-size: 12px;
  line-height: 26px;
}

.dashboard-content-main {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: stretch;
}

.main-content-header-right .custom-btn-upload {
  font-family: var(--font-myriad-regular);
  padding-left: 19px;
  padding-right: 19px;
  margin-top: 20px;
}

.main-content-header-right-stretch {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  padding-top: 15px;
}

.main-content-header-right .main-content-header-btn{
  padding: 6px 26px;
  min-width: 170px;
  text-align: center;
  margin-top: 65px;
}

.main-content-header-right .btn + .btn {
  margin-left: 20px;
}


.dashboard-content-main .card:nth-child(odd) {
  /*width: 460px;*/
  width: 40%;
}

.dashboard-content-main .card:nth-child(even) {
  margin-left: 20px;
  width: calc(60% - 20px);
  /*width: 60%;*/
}


.dashboard-content .card {
  box-shadow: 0.629px -0.777px 35px 0px rgba(0, 0, 0, 0.16);
}

.custom-spinner-row {
  padding-top: 250px;
  margin-left: auto;
  margin-right: auto;
}

.modal-body-welcome-img {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.modal-body-welcome-text {
  text-align: center;
  padding: 30px 0;
  line-height: 30px;
  font-size: 18px;
  width: 100%;
}

.main-content-header-right-load {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-wrap: wrap;
  margin-left: 30px;
}

.main-content-header-right-load .select-car-model-error,
.main-content-header-right-load .parsePDFerror {
  width: 100%;
  text-align: right;
}

.main-content-header-right-load .custom-spinner {

}

.topbar .topbar-left,
.topbar .navbar-custom,
.topbar .button-menu-mobile {
  background-color: #fff;
}

.logo span img {
  vertical-align: baseline;
}

.topbar .topbar-left {
  border-right: 1px solid #c2c8d9
}

.navbar-custom {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  box-shadow: 0.669px 0.743px 6px 0 rgba(0, 0, 0, 0.23);
}

.navbar-custom ul {
  display: flex;
  align-items: center;
}

.app-search .form-control, .app-search .form-control:focus {
  background-color: #f8f9fa;
  border: 1px solid #e9ecef;
}

.side-menu {
  background-color: var(--sidebarBackground);
}

#sidebar-menu {
  padding-top: 30px;
}

#sidebar-menu > ul > li > a.active,
#sidebar-menu > ul > li > a:hover {
  background-color: var(--sidebarBackgroundHover);
  color: #fff !important;
}

.notification-list .nav-link {
  line-height: 50px;
}

.notification-list .noti-icon-badge {
  display: inline-block;
  position: absolute;
  top: 10px;
  right: 5px;
  padding-left: 6px;
  padding-right: 6px;
  line-height: 10px;
}


#wrapper {
  height: auto;
  min-height: 100% !important;
}

#sidebar-menu {
  padding-top: 0;
}

.metismenu {

  margin: 0;
}

.metismenu > li {
  max-height: 52px;
  line-height: 0;
}

.metismenu > li a {
  line-height: 24px;
}


.topbar .dropdown-item,
.topbar .dropdown-menu {
  background: #f8f9fa;
}


.dropdown-item.notify-item {
  background-color: #fff;
  position: relative;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 5px;
  width: calc( 100% - 10px);
}


.dropdown-item-close {
  position: absolute !important;
  top: 5px;
  right: 5px;
  cursor: pointer;
  width: 16px;
  height: 16px;
  text-align: center;
  line-height: 16px;
  display: block;
  transition: all linear 0.2s;
  border: 1px solid transparent;
  border-radius: 3px;
}

.dropdown-item-close:hover {
  color: var(--mainColor);
  border-color: var(--mainColor);
}

.dropdown-item-close:before{
  content: "\F156";
  font: normal normal normal 14px/1 "Material Design Icons"
}

.custom-btn-upgrade {
  font-family: var(--font-myriad-regular);
  white-space: nowrap;
}

.dropdown-icon {
  display: inline-block;
  margin-left: 5px;
  position: relative;
  z-index: 1;
  width: 10px;
  height: 10px;
}

.dropdown-icon:before {
  content: '';
  display: block;
  position: absolute;
  top: 3px;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 5px solid #000;
}

.main-content-header-right label.custom-btn {
  margin-bottom: 0;
  position: relative;
  z-index: 1;
}

.main-content-header-right label.custom-btn input{
  display: none;
}

.custom-spinner {
  width: 20px;
  height: 20px;
  border: 2px solid var(--mainColorHover);
  border-right-color: transparent;
  animation: spinner-border .75s linear infinite;
  border-radius: 50%;
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
  margin-bottom: 5px;
}


.topbar-messageLimit {
  font-size: 12px;
  padding: 11px 30px;
  padding-left: 60px;
  box-sizing: border-box;
  position: relative;
  z-index: 1;
  border-left: 1px solid #c2c8d9;
  width: 625px;
}

.topbar-messageLimit span {
  position: absolute;
  top: 50%;
  left: 20px;
  margin-top: -10px;
  font-size: 18px;
  color: #f5b225;
}

.topbar-list li {
  flex: 0 0 auto;
}


.topbar-list li:last-child {
  width: 100%;
}


.topbar .navbar-custom .navbar-right {
  flex: 0 0 auto
}

.speech-bubble_postYourCar {
  position: absolute;
  transform-origin: 0% 100%;
  text-align: center;
  background-color: rgba(90, 169, 255, 0.9);
  color: white;
  border-radius: 10px;
  width: 40%;
  padding: 10px;
  left: 635px;
  top: 70px;
  animation-fill-mode: forwards;
  animation-duration: 0.25s;
  z-index: 888;
  max-width: 800px;
  margin: 10px;
  min-width: 256px;
}

.speech-bubble_findTransporter {
  position: absolute;
  transform-origin: 0% 100%;
  text-align: center;
  background-color: rgba(90, 169, 255, 0.9);
  color: white;
  border-radius: 10px;
  width: 80%;
  padding: 10px;
  left: 255px;
  top: 210px;
  animation-fill-mode: forwards;
  animation-duration: 0.25s;
  z-index: 1000;
  max-width: 800px;
}

.speech-bubble_findTransporter::before {
  content: "";
  display: block;
  width: 0;
  position: absolute;
  top: -15px;
  left: 55px;
  border-style: solid;
  border-width: 15px;
  border-color: rgba(90, 169, 255, 0.9) transparent transparent rgba(90, 169, 255, 0.9);
  transform: rotate(45deg);
  z-index: 888;
}

.speech-bubble_loadCreated {
  position: absolute;
  transform-origin: 0% 100%;
  text-align: center;
  background-color: rgba(90, 169, 255, 0.9);
  color: white;
  border-radius: 10px;
  width: 80%;
  padding: 10px;
  left: 255px;
  top: 425px;
  animation-fill-mode: forwards;
  animation-duration: 0.25s;
  z-index: 1000;
  max-width: 800px;
}

.speech-bubble_loadCreated::before {
  content: "";
  display: block;
  width: 0;
  position: absolute;
  top: -15px;
  left: 55px;
  border-style: solid;
  border-width: 15px;
  border-color: rgba(90, 169, 255, 0.9) transparent transparent rgba(90, 169, 255, 0.9);
  transform: rotate(45deg);
  z-index: 888;
}

.speech-bubble_showUploadPdf {
  position: absolute;
  transform-origin: 0% 100%;
  text-align: center;
  background-color: rgba(90, 169, 255, 0.9);
  color: white;
  border-radius: 10px;
  width: 80%;
  padding: 10px;
  left: 630px;
  top: 70px;
  animation-fill-mode: forwards;
  animation-duration: 0.25s;
  z-index: 888;
  max-width: 600px;
  margin: 10px;
  min-width: 256px;
}

.speech-bubble_showAdjustDates {
  position: absolute;
  transform-origin: 0% 100%;
  text-align: center;
  background-color: rgba(90, 169, 255, 0.9);
  color: white;
  border-radius: 10px;
  width: 40%;
  padding: 10px;
  left: 600px;
  top: 235px;
  animation-fill-mode: forwards;
  animation-duration: 0.25s;
  z-index: 888;
  max-width: 600px;
  margin: 10px;
  min-width: 256px;
}

.speech-bubble_showDesirableRate {
  position: absolute;
  transform-origin: 0% 100%;
  text-align: center;
  background-color: rgba(90, 169, 255, 0.9);
  color: white;
  border-radius: 10px;
  width: 40%;
  padding: 10px;
  left: 600px;
  top: 395px;
  animation-fill-mode: forwards;
  animation-duration: 0.25s;
  z-index: 888;
  max-width: 600px;
  margin: 10px;
  min-width: 256px;
}

.speech-bubble_showAddVehicle {
  position: absolute;
  transform-origin: 0% 100%;
  text-align: center;
  background-color: rgba(90, 169, 255, 0.9);
  color: white;
  border-radius: 10px;
  width: 40%;
  padding: 10px;
  left: 470px;
  top: 545px;
  animation-fill-mode: forwards;
  animation-duration: 0.25s;
  z-index: 888;
  max-width: 600px;
  margin: 10px;
  min-width: 256px;
}

.speech-bubble_showAttachExtras {
  position: absolute;
  transform-origin: 0% 100%;
  text-align: center;
  background-color: rgba(90, 169, 255, 0.9);
  color: white;
  border-radius: 10px;
  width: 40%;
  padding: 10px;
  left: 570px;
  top: 635px;
  animation-fill-mode: forwards;
  animation-duration: 0.25s;
  z-index: 888;
  max-width: 600px;
  margin: 10px;
  min-width: 256px;
}

.speech-bubble_showCorrectAddress {
  position: absolute;
  transform-origin: 0% 100%;
  text-align: center;
  background-color: rgba(90, 169, 255, 0.9);
  color: white;
  border-radius: 10px;
  width: 40%;
  padding: 10px;
  left: 570px;
  top: 1005px;
  animation-fill-mode: forwards;
  animation-duration: 0.25s;
  z-index: 888;
  max-width: 600px;
  margin: 10px;
  min-width: 256px;
}

.speech-bubble_showCorrectAddress::before {
  content: "";
  display: block;
  width: 0;
  position: absolute;
  top: 40px;
  left: -15px;
  border-style: solid;
  border-width: 15px;
  border-color: rgba(90, 169, 255, 0.9) transparent transparent rgba(90, 169, 255, 0.9);
  transform: rotate(315deg);
  z-index: 888;
}

.speech-bubble_showAddPaymentTerms {
  position: absolute;
  transform-origin: 0% 100%;
  text-align: center;
  background-color: rgba(90, 169, 255, 0.9);
  color: white;
  border-radius: 10px;
  width: 40%;
  padding: 10px;
  left: 570px;
  top: 1785px;
  animation-fill-mode: forwards;
  animation-duration: 0.25s;
  z-index: 888;
  max-width: 600px;
  margin: 10px;
  min-width: 256px;
}

.speech-bubble_showAddPaymentTerms::before {
  content: "";
  display: block;
  width: 0;
  position: absolute;
  top: 40px;
  left: -15px;
  border-style: solid;
  border-width: 15px;
  border-color: rgba(90, 169, 255, 0.9) transparent transparent rgba(90, 169, 255, 0.9);
  transform: rotate(315deg);
  z-index: 888;
}

.speech-bubble-expand {
  animation-name: "expand-bounce";
}

.speech-bubble-shrink {
  animation-name: "shrink";
}

.speech-bubble_postYourCar::before {
  content: "";
  display: block;
  width: 0;
  position: absolute;
  top: 30px;
  left: -15px;
  border-style: solid;
  border-width: 15px;
  border-color: rgba(90, 169, 255, 0.9) transparent transparent rgba(90, 169, 255, 0.9);
  transform: rotate(315deg);
  z-index: 888;
}

.speech-bubble_showUploadPdf::before {
  content: "";
  display: block;
  width: 0;
  position: absolute;
  top: 40px;
  left: -15px;
  border-style: solid;
  border-width: 15px;
  border-color: rgba(90, 169, 255, 0.9) transparent transparent rgba(90, 169, 255, 0.9);
  transform: rotate(315deg);
  z-index: 888;
}

.speech-bubble_showAdjustDates::before {
  content: "";
  display: block;
  width: 0;
  position: absolute;
  top: 40px;
  left: -15px;
  border-style: solid;
  border-width: 15px;
  border-color: rgba(90, 169, 255, 0.9) transparent transparent rgba(90, 169, 255, 0.9);
  transform: rotate(315deg);
  z-index: 888;
}

.speech-bubble_showDesirableRate::before {
  content: "";
  display: block;
  width: 0;
  position: absolute;
  top: 40px;
  left: -15px;
  border-style: solid;
  border-width: 15px;
  border-color: rgba(90, 169, 255, 0.9) transparent transparent rgba(90, 169, 255, 0.9);
  transform: rotate(315deg);
  z-index: 888;
}

.speech-bubble_showAttachExtras::before {
  content: "";
  display: block;
  width: 0;
  position: absolute;
  top: 40px;
  left: -15px;
  border-style: solid;
  border-width: 15px;
  border-color: rgba(90, 169, 255, 0.9) transparent transparent rgba(90, 169, 255, 0.9);
  transform: rotate(315deg);
  z-index: 888;
}

.speech-bubble_showAddVehicle::before {
  content: "";
  display: block;
  width: 0;
  position: absolute;
  top: -15px;
  left: 35px;
  border-style: solid;
  border-width: 15px;
  border-color: rgba(90, 169, 255, 0.9) transparent transparent rgba(90, 169, 255, 0.9);
  transform: rotate(45deg);
  z-index: 888;
}

@media (max-width: 1436px) {
  .speech-bubble_postYourCar {
    width: auto;
    top: 160px;
    left: 85px;
    background-color: rgba(90, 169, 255, 0.9);
  }

  .speech-bubble_postYourCar::before {
    top: -15px;
    left: 265px;
    transform: rotate(45deg);
  }

  .speech-bubble_findTransporter {
    left: 85px;
  }

  .speech-bubble_loadCreated {
    left: 85px;
  }

  .speech-bubble_showUploadPdf {
    width: auto;
    left: 185px;
    top: 165px;
    background-color: rgba(90, 169, 255, 0.9);
  }
  .speech-bubble_showUploadPdf::before {
    top: -15px;
    left: 175px;
    transform: rotate(45deg);
  }
  .speech-bubble_showAdjustDates {
    width: auto;
    left: 85px;
    top: 385px;
    background-color: rgba(90, 169, 255, 0.9);
  }
  .speech-bubble_showAdjustDates::before {
    top: -15px;
    left: 55px;
    transform: rotate(45deg);
  }

  .speech-bubble_showDesirableRate {
    width: auto;
    left: 85px;
    top: 485px;
    background-color: rgba(90, 169, 255, 0.9);
  }
  .speech-bubble_showDesirableRate::before {
    top: -15px;
    left: 55px;
    transform: rotate(45deg);
  }

  .speech-bubble_showAttachExtras {
    left: 275px;
    top: 730px;
    width: auto; 
    background-color: rgba(90, 169, 255, 0.9);
  }
  .speech-bubble_showAttachExtras::before {
    top: -15px;
    left: 35px;
    transform: rotate(45deg);
  }

  .speech-bubble_showAddVehicle {
    width: auto;
    left: 85px;
    background-color: rgba(90, 169, 255, 0.9);
  }
  .speech-bubble_showAddVehicle::before {
    top: -15px;
    left: 55px;
    transform: rotate(45deg);
  }

  .speech-bubble_showCorrectAddress {
    left: 270px;
    top: 1105px;
    width: auto; 
    background-color: rgba(90, 169, 255, 0.9);
  }
  .speech-bubble_showCorrectAddress::before {
    top: -15px;
    left: 35px;
    transform: rotate(45deg);
  }

  .speech-bubble_showAddPaymentTerms {
    left: 270px;
    top: 1885px;
    width: auto; 
    background-color: rgba(90, 169, 255, 0.9);
  }
  .speech-bubble_showAddPaymentTerms::before {
    top: -15px;
    left: 35px;
    transform: rotate(45deg);
  }
}

@media (max-width: 480px) {
  .speech-bubble_postYourCar {
    left: 85px;
    background-color: rgba(90, 169, 255, 0.9);
    margin-right: auto;
    width: 75%;
  }

  .speech-bubble_findTransporter {
    left: 85px;
  }

  .speech-bubble_loadCreated {
    left: 85px;
  }

  .speech-bubble_showUploadPdf {
    width: 70%;
    left: 105px;
    top: 165px;
    margin-right: auto;
    background-color: rgba(90, 169, 255, 0.9);
  }
  .speech-bubble_showUploadPdf::before {
    top: -15px;
    left: 235px;
    transform: rotate(45deg);
  }
  .speech-bubble_showAdjustDates {
    width: 60%;
    left: 85px;
    top: 385px;
    margin-right: auto;
    background-color: rgba(90, 169, 255, 0.9);
  }
  .speech-bubble_showDesirableRate {
    left: 85px;
    background-color: rgba(90, 169, 255, 0.9);
  }
  .speech-bubble_showAttachExtras {
    width: 75%;
    left: 85px;
    margin-right: auto;
    background-color: rgba(90, 169, 255, 0.9);
  }
  .speech-bubble_showAddVehicle {
    left: 85px;
    margin-right: auto;
    background-color: rgba(90, 169, 255, 0.9);
  }

  .speech-bubble_showCorrectAddress {
    width: 75%;
    left: 85px;
    margin-right: auto;
    background-color: rgba(90, 169, 255, 0.9);
  }

  .speech-bubble_showCorrectAddress::before {
    top: -15px;
    left: 215px;
    transform: rotate(45deg);
  }

  .speech-bubble_showAddPaymentTerms {
    width: 75%;
    left: 85px;
    margin-right: auto;
    background-color: rgba(90, 169, 255, 0.9);
  }
  .speech-bubble_showAddPaymentTerms::before {
    top: -15px;
    left: 65px;
    transform: rotate(45deg);
  }
}

@media (max-width: 419px) {
  .speech-bubble_postYourCar {
    width: 90%;
    top: 160px;
    left: 15px;
    margin: auto;
    background-color: rgba(90, 169, 255, 0.9);
  }

  .speech-bubble_findTransporter {
    left: 15px;
    width: 90%;
  }

  .speech-bubble_loadCreated {
    left: 15px;
    width: 90%;
  }

  .speech-bubble_showUploadPdf {
    width: 90%;
    top: 175px;
    left: 15px;
    margin: auto;
    background-color: rgba(90, 169, 255, 0.9);
  }

  .speech-bubble_showAdjustDates {
    width: 90%;
    left: 15px;
    top: 395px;
    margin: auto;
    background-color: rgba(90, 169, 255, 0.9);
  }

  .speech-bubble_showDesirableRate {
    width: 90%;
    left: 15px;
    top: 485px;
    margin: auto;
    background-color: rgba(90, 169, 255, 0.9);
  }

  .speech-bubble_showAttachExtras {
    width: 90%;
    top: 740px;
    left: 15px;
    margin: auto;
    background-color: rgba(90, 169, 255, 0.9);
  }

  .speech-bubble_showAddVehicle {
    width: 90%;
    left: 15px;
    margin: auto;
    top: 555px;
    background-color: rgba(90, 169, 255, 0.9);
  }

  .speech-bubble_showCorrectAddress {
    width: 90%;
    left: 15px;
    margin: auto;
    top: 1105px;
    background-color: rgba(90, 169, 255, 0.9);
  }

  .speech-bubble_showAddPaymentTerms {
    width: 90%;
    left: 15px;
    margin: auto;
    top: 1895px;
    background-color: rgba(90, 169, 255, 0.9);
  }

  .speech-bubble_postYourCar::before {
    scale: 0;
  }
  
   .speech-bubble_showUploadPdf::before {
    scale: 0;
  }
}

@media (pointer:none), (pointer:coarse) {
  .speech-bubble_postYourCar {
    left: 85px;
    background-color: rgba(90, 169, 255, 0.9);
  }
  .speech-bubble_showUploadPdf {
    left: 85px;
    background-color: rgba(90, 169, 255, 0.9);
  }
  .speech-bubble_showAdjustDates {
    left: 85px;
    background-color: rgba(90, 169, 255, 0.9);
  }
  .speech-bubble_showDesirableRate {
    left: 85px;
    background-color: rgba(90, 169, 255, 0.9);
  }
  .speech-bubble_showAttachExtras {
    left: 85px;
    background-color: rgba(90, 169, 255, 0.9);
  }
  .speech-bubble_showAddVehicle {
    left: 85px;
    background-color: rgba(90, 169, 255, 0.9);
  }
}

@keyframes expand-bounce {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(0.5);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes shrink {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.5);
  }
  100% {
    transform: scale(0);
  }
}

.button-speech-bubble {
  border: none;
  position: relative;
  float: right;
  color: #525f80;
  font-size: 24px;
  background-color: rgba(0, 0, 0, 0);
}

.button-speech-bubble:hover {
  cursor: pointer;
}

.speech-link {
  text-decoration: none;
  color: #525f80;
}

.youtube-speech-bubble {
  border: none;
  position: relative;
  vertical-align:middle;
  /* float: right; */
  color: #525f80;
  /* font-size: 24px; */
  background-color: rgba(0, 0, 0, 0);
}

.youtube-speech-bubble:hover {
  cursor: pointer;
}

.howtouse-speech-bubble {
  border: none;
  position: relative;
  vertical-align:middle;
  color: #525f80;
  background-color: rgba(0, 0, 0, 0);
}

.howtouse-speech-bubble:hover {
  cursor: pointer;
}

.speech-text {
  color: white;
  text-align: left;
}

.speech-title {
  color: white;
  padding-bottom: 30px;
  text-align: center;
}

.speech-content {
  margin: 20px 20px 20px 20px
}
.custom-card-help .card-body {
  display: flex;
}



.custom-card-help-right img {
  max-width: 100%;
  display: inline-block;
  margin: 0 auto;
  box-shadow: 0 0px 12px rgba(0, 0, 0, 0.4);
}

.custom-card-help-right-expanded img {
  max-width: 100%;
  max-height: 100%;
  margin: 0 0 0 0;
  box-shadow: 0 0px 12px rgba(0, 0, 0, 0.4);
}

.custom-card-help-left {
  font-size: 14px;
  line-height: 22px;
  padding-right: 50px;
  width: 65%;
}


.custom-card-help-right {
  width: 35%;
  text-align: center;
  padding-top: 40px;
  padding-right: 10px;
}

.custom-card-help-right-expanded {
  display: flex; 
  align-items: center; 
  justify-content: flex-start;
  max-width: 720px;
  max-height: 480px;
  height: auto;
}

.custom-card-help-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.custom-card-help-header .custom-btn {
  font-family: var(--font-myriadSemibold-regular);
  font-size: 14px;
}

.custom-card-help h5 {
  font-size: 18px;
}

.help-item-header {
  display: flex;
  justify-content: space-between;
  border: 1px solid #ccc;
  border-radius: 3px;
  padding:  12px 20px;
  align-items: center;
  background: linear-gradient(to top, rgb(242,244,247) 0%, rgb(254,255,255) 100%);
  margin-top: 15px;
}

.help-item-header button {
  border: 1px solid var(--mainColor);
  color: var(--mainColor);
  border-radius: 3px;
  padding: 0;
  margin: 0;
  width: 34px;
  height: 34px;
  background-color: #fff;
  font-weight: bold;
  cursor: pointer;
}


.help-item-header button:before {
  content: '-';
}

.help-item-header button.collapsed:before {
  content: '+';
}

.help-item-title {
  font-weight: bold;
  font-size: 16px;
  font-family: var(--font-openSans-bold);
  color: #58595b;
}


.help-item-body {
  background-color: #f1f1f1;
  padding: 20px;
  font-size: 14px;
  line-height: 22px;
}

.custom-card-help-header h5 {
  font-size: 18px;
}


.help-item-body p,
.help-item-body ul li,
.help-item-body ol li {
  margin-bottom: 12px;
}

.client-card {
  border-radius: 4px;
  box-shadow: 0.629px -0.777px 35px 0px rgba(0, 0, 0, 0.16);
  width: calc(25% - 15px);
  margin-right: 20px;
  margin-bottom: 20px;
  overflow: hidden;
  background-color: #fff;
  position: relative;
  z-index: 1;
  padding-bottom: 60px;
}

.client-card:nth-child(4n+4) {
  margin-right: 0;
}

.client-card-header {
  position: relative;
  z-index: 1;
}

.client-card-header-background img {
  width: 100%;
  height: 151px;
  object-fit: cover;
  position: relative;
  z-index: 1;
}

.client-card-header:before {
  content: '';
  display: block;
  width: 100%;
  height: 151px;
  background-color: #4875a5;
  opacity:  0.72;
  z-index: 5;
  position: absolute;
  top: 0;
}

.client-card-header-edit {
  position: absolute;
  cursor: pointer;
  top: 10px;
  right: 15px;
  z-index: 10;
  font-size: 13px;
  font-weight: bold;
  transition: all linear 0.2s;
  color: #fff;
  letter-spacing: 1px;
  font-family: var(--font-semibold);
}

.client-card-header-edit:hover {
  color: #fff;
  text-decoration: underline;
}

.client-card-header-logo {
  position: absolute;
  border-radius: 50%;
  bottom: -44px;
  left: 0;
  right: 0;
  margin: 0 auto;
  background-color: #fff;
  height: 88px;
  width: 88px;
  box-shadow: -1px 1.732px 3.8px 0.2px rgba(29, 34, 49, 0.25);
  z-index: 10;
}

.client-card-header-logo img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: calc(100% - 30px);
  max-height: calc(100% - 30px);
  width: auto;
  height: auto;
  margin: auto;
}

.client-card-list {
  list-style: none;
  text-align: center;
  padding-top: 64px;
  padding-left: 15px;
  padding-right: 15px;
}

.client-card-list li:nth-child(3n+3){
  margin-bottom: 10px;
  padding-bottom: 10px;
}

.client-card-list li:last-child {
  margin-top: 20px;
}

.client-card-name {
  font-size: 18px;
  font-weight: 500;
  color: #212224;
  line-height: 26px;
  margin-bottom: 10px;
  font-family: var(--font-semibold);
}

.client-card-text {
  line-height: 30px;
  font-size: 13px;
  color: #212224
}

.client-card-footer {
  border-top: 1px solid #e1e2e5;
  display: flex;
  padding-top: 20px;
  justify-content: center;
  position: absolute;
  bottom: 20px;
  background-color: #fff;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: calc(100% - 40px);
}

@media(max-width: 1500px) {
  .client-card {
    width: calc(33.3333333% - 20px);
    margin-right: 30px;
  }

  .client-card:nth-child(4n+4) {
    margin-right: auto;
  }

  .client-card:nth-child(3n+3) {
    margin-right: 0px;
  }
}

.custom-modal .modal-body,
.custom-modal .modal-header {
  padding-left: 30px;
  padding-right: 30px;
}

.custom-modal .form-group{
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.custom-modal .form-group input {
  width: calc(100% - 160px);
}

/*.custom-modal .form-group .invalid-feedback {*/
  /*text-align: right;*/
/*}*/

.custom-modal .custom-file p,
.custom-modal label:not(.btn){
  font-size: 13px;
  color: #212224;
  flex: 0 0 auto;
  width: 160px;
  line-height: 15px;
}

.custom-modal .custom-file {
  display: flex;
  align-items: center;
}

.custom-modal .custom-file-name {
  font-size: 14px;
  padding-left: 30px;
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.custom-modal .custom-file .btn {
  flex: 0 0 auto;
  font-family: var(--font-myriadSemibold-regular);
}

.custom-modal [type="file"] {
  display: none;
}

.custom-modal .custom-file label,
.custom-modal .custom-file p {
  margin-bottom: 0;
}

.custom-modal .custom-file label {
  line-height: 18px;
}

.custom-modal .custom-file label:before {
  content: "\F07C";
  font-family: 'Font Awesome 5 Free', Arial, "Helvetica Neue", Helvetica, sans-serif;
  display: inline-block;
  margin-right: 13px;
}

.custom-modal .modal-body-clients .invalid-feedback {
  width: 100%;
  text-align: left;
  padding-left: 160px;
}

.custom-modal .modal-title {
  font-size: 18px;
  font-family: var(--font-semibold);
}

.subscription-info {
  box-shadow: 0.629px -0.777px 35px 0px rgba(0, 0, 0, 0.16);
  border-radius: 4px;
  background-color: #fff;
  padding: 20px;
  width: 960px;
  margin: 0 auto;
}

.subscription-info p {
  color: #5b626b;
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 0;
}

.subscription-info-left span {
  font-size: 14px;
  color: #383838;
  font-family: var(--font-semibold);
}

.subscription-info-cancel {
  font-size: 14px;
  color: var(--mainColor);
  margin-top: 20px;
  cursor: pointer;
}

.modal-payment-list {
  list-style: none;
  padding: 0;
  margin: 0;
  padding-bottom: 60px;
}

.modal-payment-list-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 50px;
}

.modal-payment-list-item:last-child {
  margin-bottom: 0;
}

.modal-payment-list .custom-btn {
  font-family: var(--font-semibold);
}

.modal-payment-list-item-img {
  border: 1px solid  rgb(206, 208, 218);
  border-radius: 10px;
  background-image: -ms-linear-gradient( 90deg, rgb(242,244,247) 0%, rgb(254,255,255) 100%);
  width: 150px;
  height: 78px;
  position: relative;
  z-index: 1;
  margin-right: 10px;
}

.modal-payment-list-item-img img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  margin: auto;
}


.modal-payment-list-item-name {
  font-size: 13px;
  color: #212224;
}

.modal-payment-list .custom-btn {
  margin-left: auto;
}

.stripeForm {
  padding: 50px 90px 20px 90px;
}

.stripeForm button {
  display: block;
  width: 260px;
  margin: 0 auto;
  height: 37px;
  background-color: var(--mainColor);
  border-radius: 2px;
  font-weight: 600;
  color: #fff;
  cursor: pointer;
  border: none;
  transition: all linear 0.2s;
  margin-top: 65px;
  margin-bottom: 35px;
  font-family: var(--font-myriadSemibold-regular);
}

.stripeForm button:hover {
  background-color: var(--mainColorHover);
}

.StripeElement {
  box-sizing: border-box;

  height: 40px;

  padding: 10px 12px;

  border: 1px solid transparent;
  border-radius: 4px;
  background-color: white;

  box-shadow: 0 1px 3px 0 #b9bdc1;
  transition: box-shadow 150ms ease;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #707273;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

.stripeForm label {
  width: 100% !important;
}

.stripeFormText {
  font-size: 14px;
  color: #a9a6a6;
  margin-top: 20px;
  letter-spacing: 1px;
}
.stripeFormText b {
  color: var(--mainColor);
  font-family: var(--font-semibold)
}

.stripeForm .custom-alert {

  text-align: center;
  margin-top: 40px;
}

.payment-content {
  padding-top: 50px;
}

.payment-content-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 18px;
}

.payment-content-item p {
  margin-bottom: 0;
  font-size: 14px;
  color: #5b626b;
  font-family: var(--font-medium);
}

.payment-content-item span {
  color: var(--mainColor);
  font-size: 14px;
}

.payment-content-item span b {
  font-family: var(--font-medium);
  font-weight: bold;
  font-size: 18px;
}

.payment-content-footer {
  padding-top: 10px;
  margin-top: 50px;
  border-top: 1px solid #bfbfbf;
}

.payment-content-footer p {
  font-size: 13px;
  color: #808080;
}

.dispatcher-pro {
  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: 4px;
  background-color: #fff;
  width: 960px;
  margin: 0 auto;
}

.dispatcher-pro-title {
  width: 100%;
  font-family: var(--font-medium);
  font-size: 18px;
  color: #fff;
  font-weight: 500;
  padding: 20px;
  background-color: var(--mainColor);
}

.dispatcher-pro-content {
  width: 100%;
  background-color: #fff;
  padding: 20px;
  color: #666C73;
}

.dispatcher-pro-cta {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-family: var(--font-medium);
  font-size: 18px;
  background-color: var(--mainColor);
  width: 200px;
  height: 40px;
  margin-bottom: 20px;
}
.user-plan {
  width: 960px;
  margin: 0 auto;
  padding-top: 40px;
}

.user-plan-logo {
  margin: 0 auto;
  width: 91px;
  height: 68px;
  margin-bottom: 42px;
}

.user-plan-row {
  display: flex;
  justify-content: space-between;
}

.user-plan-col {
  width: calc(50% - 18px);
  border: 1px solid #c2c8d9;
  background-color: #fff;
  margin-bottom: 25px;
}

.user-plan-info h4 {
  text-align: center;
  text-transform: uppercase;
  font-size: 18px;
  padding-top: 30px;
  padding-bottom: 15px;
}

.user-plan-info p {
  text-align: center;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 45px;
}

.user-plan-info-list {
  list-style-type: none;
  width: 85%;
  margin: 0 auto;
  margin-bottom: 35px;
}

.user-plan-info-list li {
  margin-bottom: 15px;
  font-weight: 500;
  font-size: 14px;
  font-family: var(--font-medium);
}

.user-plan-info-list li:last-child {
  margin-bottom: 0;
}

.user-plan-info-list i {
  color: var(--mainColor);
  margin-right: 18px;
}

.user-plan-info-drivers p {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 15px;
}

.user-plan-info-drivers-count {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  margin-bottom: 25px;
}

.user-plan-info-drivers-count .input-group,
.user-plan-info-drivers-count .custom-btn {
  width: 190px;
}

.user-plan-info-drivers-count .count-minus .input-group-text{
  border-top-left-radius: 3px !important;
  border-bottom-left-radius: 3px !important;
  border-right: none;
}

.user-plan-info-drivers-count .count-plus .input-group-text {
  border-left: none;
}

.user-plan-info-drivers-count .input-group-text {
  background-color: var(--mainColor);
  color: #fff;
}

.user-plan-info-sup {
  text-align: center;
  font-size: 11px;
  margin-bottom: 20px;
  line-height: 18px;
  padding-left: 20px;
  padding-right: 20px;
}

.user-plan-info-sup.error {
  font-size: 13px;
}

.user-plan-info-drivers-count .form-control {
  background-color: #f5f5f5;
  text-align: center;
  font-weight: bold;
}

.user-plan-view-title {
  font-weight: 500;
  color: #fff;
  text-transform: uppercase;
  font-size: 18px;
  text-align: center;
  padding: 36px 0;
  background-color: var(--mainColor);
  font-family: var(--font-medium);
}

.user-plan-view-list {
  padding: 32px 30px 35px 30px;
}

.user-plan-view-list p,
.user-plan-view-list span {
  padding: 0;
  margin: 0;
  font-family: var(--font-medium);

}


.user-plan-view-list li {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.user-plan-view-list li:not(:last-child) {
  border-bottom: 1px solid #bfbfbf;
  padding-bottom: 30px;
  margin-bottom: 30px;
  font-size: 18px;
  line-height: 26px;
  font-weight: 500;
  color: #5b626b
}

.user-plan-view-list li:last-child {
  font-weight: bold;
  color: #5b626b;
  font-size: 20px;
}

.user-plan-submit {
  display: flex;
  width: 260px;
  margin: 0 auto;
  justify-content: center;
}

@media(max-width: 968px) {
  .user-plan-row {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.user-plan-info .input-group-append {
  cursor: pointer;
}


.people-table {
  box-shadow: 0.629px -0.777px 35px 0px rgba(0, 0, 0, 0.16);
  background-color: rgb(255, 255, 255);
  border-radius: 4px;
  padding: 20px;
}

.people-table table td:first-child,
.people-table table th:first-child {
  width: 140px;
}

.people-table table td:nth-child(2),
.people-table table th:nth-child(2) {
  width: auto;
}

.people-table table td:nth-child(3),
.people-table table th:nth-child(3) {
  width: 100px;
}

.people-table table td:nth-child(4),
.people-table table th:nth-child(4) {
  width: 100px;
}

.people-table table td:nth-child(5),
.people-table table th:nth-child(5) {
  width: 100px;
}

.people-table table td:nth-child(6),
.people-table table th:nth-child(6) {
  width: auto;
}

.people-table table td:nth-child(7),
.people-table table th:nth-child(7) {
  width: 80px;
}

.people-table table td:nth-child(8),
.people-table table th:nth-child(8) {
  width: auto;
}

.people-table table td:nth-child(9),
.people-table table th:nth-child(9) {
  width: auto;
}

.people-table table td:nth-child(10),
.people-table table th:nth-child(10) {
  width: auto;
}

.people-table table td:nth-child(11),
.people-table table th:nth-child(11) {
  width: auto;
}

.people-table table td:last-child,
.people-table table th:last-child {
  width: 100px;
}


.people-table-header {
  display: flex;
  justify-content: space-between;
  padding-bottom: 12px;
}

.people-table table th{
  font-family: var(--font-medium);
  font-size: 12px;
}

.people-table-header-title {
  color: #5b626b;
  font-family: var(--font-medium);
  font-size: 16px;
}

.people-table-header-map {
  color: var(--mainColor);
  font-size: 14px;
  cursor: pointer;
}

.people-table-header-search {
  color: #b7b8b9;
  font-size: 12px;
  font-family: var(--font-medium);
  text-align: right;
}

.people-table-comment-text p {
  color: var(--mainColor);
  cursor: pointer;
}

.people-table-comment-form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.people-table-comment-form-btns {
  display: flex;
  align-items: center;
}
.people-table-comment-form-btns._widget {
  justify-content: center;
}

.people-table-comment-form-btns p {
  margin-right: 8px;
}

.people-table-comment-form-btns span {
  color: #dc3545;
  font-size: 12px;
  cursor: pointer;
}

.people-table-comment-text {
  font-size: 12px;
  margin-bottom: 4px;
}

.people-table-comment-form textarea {
  display: block;
  width: 100%;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  padding: .375rem .75rem;
  margin-bottom: 5px;
  resize: vertical ;
}

.people-table-comment-form p,
.people-table-comment-control p{
  color: var(--mainColor);
  font-size: 12px;
  cursor: pointer;
  margin-bottom: 0;
}

.people-table-comment-form span {
  color: #dc3545;
  font-size: 12px;
  cursor: pointer;
  line-height: 1.7;
}

.people-table-capacity span:first-child {
  font-weight: bold;
  color: #419c5f;
}

.people-table-capacity span:last-child {
  color: #000;
}

.people-table-footer .btn {
  margin-right: 10px;
  margin-bottom: 20px;
}

.people-table table {
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;
  border-top: 2px solid var(--mainColor);
  margin-bottom: 20px;
}

.people-table tbody tr.tr-first:nth-child(odd){
  background-color: #f8f9fa
}

.people-table table th,
.people-table table td{
  padding: 14px 10px;
  font-size: 12px;
  color: #212224;
  line-height: 16px;
  word-break: break-word;
}

.people-table table img {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  margin-right: 10px;
}
.people-table table img.dropdown {
  border-radius: 50%;
  width: 16px;
  height: 16px;
  margin-right: 8px;
}

.people-table-history table th:first-child,
.people-table-history table td:first-child {
  width: 50px;
}

.people-table .custom-control {
  width: 0;
  margin: 0;
  padding-left: 30px;
}

.people-table .custom-control label {
  cursor: pointer;
}

.people-table-btn-group-single-delete {
  width: 48px !important;
}

.people-table-status-active,
.people-table-status-disabled,
.people-table-status-alert,
.people-table-status-assigned,
.people-table-status-dispatched,
.people-table-status-picked{
  padding: 6px 7px 4px;
  width: 80px;
  text-align: center;
  text-transform: uppercase;
  border-radius: 3px;
  color: #fff;
  display: inline-block;
  font-size: 12px;
  line-height: 14px;
  font-family: var(--font-myriadSemibold-regular);
}

.people-table-status-active {
  background-color: #58db83;
}

.people-table-status-disabled {
  background-color: #6c757d;
}

.people-table-status-alert {
  background-color: #f5b225;
}

.people-table-status-picked {
  background-color: #6c757d;
}

.people-table-status-dispatched {
  background-color: #B24AF1;
}

.people-table-status-assigned {
  background-color: var(--mainColor);
}

.people-table-history table td:nth-child(1),
.people-table-history table th:nth-child(1) {
  width: 150px;
}

.people-table-history table td:first-child,
.people-table-history table th:first-child {
  width: 30px;
}

.people-table-history table td:nth-child(2),
.people-table-history table th:nth-child(2) {
  width: 110px;
}

.people-table-history table td:nth-child(3),
.people-table-history table th:nth-child(3) {
  width: 100px;
}

.people-table-history table td:nth-child(4),
.people-table-history table th:nth-child(4) {
  width: 100px;
}

.people-table-history table td:nth-child(5),
.people-table-history table th:nth-child(5) {
  width: auto;
}

.people-table-history table td:nth-child(6),
.people-table-history table th:nth-child(6) {
  width: 90px;
}

.people-table-history table td:nth-child(7),
.people-table-history table th:nth-child(7) {
  width: auto;
}

.people-table-history table td:nth-child(8),
.people-table-history table th:nth-child(8) {
  width: auto;
}

.people-table-history table td:nth-child(9),
.people-table-history table th:nth-child(9) {
  width: auto;
}

.people-table-history table td:nth-child(10),
.people-table-history table th:nth-child(10) {
  width: auto;
}

.people-table-history table td:last-child,
.people-table-history table th:last-child {
  width: 80px;
}

.people-table-transporter table td:first-child,
.people-table-transporter table th:first-child {
  width: auto;
}

.people-table-transporter table td:nth-child(2),
.people-table-transporter table th:nth-child(2) {
  width: 120px;
}

.people-table-transporter table td:nth-child(3),
.people-table-transporter table th:nth-child(3) {
  width: auto;
}

.people-table-transporter table td:nth-child(4),
.people-table-transporter table th:nth-child(4) {
  width: auto;
}

.people-table-transporter table td:nth-child(5),
.people-table-transporter table th:nth-child(5) {
  width: 250px;
}

.people-table-transporter table td:nth-child(6),
.people-table-transporter table th:nth-child(6) {
  width: 80px;
}

.people-table-transporter table td:nth-child(7),
.people-table-transporter table th:nth-child(7) {
  width: 100px;
}

/* .people-table-transporter table td:nth-child(3),
.people-table-transporter table th:nth-child(3),
.people-table-transporter table td:nth-child(4),
.people-table-transporter table th:nth-child(4) {
  max-width: 150px;
}

.people-table-transporter table td:nth-child(5),
.people-table-transporter table th:nth-child(5) {
  max-width: 150px;
}

.people-table-transporter table td:nth-child(6),
.people-table-transporter table th:nth-child(6) {
  max-width: 80px;
}

.people-table-transporter table td:nth-child(7),
.people-table-transporter table th:nth-child(7) {
  max-width: 100px;
} */

.people-table-transporter table td:last-child,
.people-table-transporter table th:last-child{
  width: 80px;
}


.people-table-btn-group-control {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.people-table-btn-group-control .people-table-btn-group-toggle {
  margin-right: 20px;
  width: 90px;
}

.people-table-btn-group-control .people-table-btn-group-toggle input:checked ~ .people-table-btn-group-toggle-active {
  display: block;
  padding-left: 35px;
}

.people-table-btn-group-control .people-table-btn-group-toggle input:checked ~ .people-table-btn-group-toggle-notActive {
  display: none;
}

.people-table-btn-group-control .people-table-btn-group-toggle input ~ .people-table-btn-group-toggle-active {
  display: none;
}

.people-table-btn-group-control .people-table-btn-group-toggle input ~ .people-table-btn-group-toggle-notActive {
  display: block;
  padding-left: 8px;
}

.people-table-btn-group-control .people-table-btn-group-toggle-notActive,
.people-table-btn-group-control .people-table-btn-group-toggle-active {
  font-size: 12px;
  border: 1px solid var(--status-color-dark);
  background-color: #fafafa;
  border-radius: 30px;
  position: relative;
  z-index: 1;
  width: 100%;
  display: block;
  height: 26px;
  line-height: 24px;
  font-family: var(--font-myriadSemibold-regular);
  color: var(--status-color-dark);
  margin-bottom: 0;
  transition: all linear 0.25s

}


.people-table-btn-group-control .people-table-btn-group-toggle label {
  position: relative;
  z-index: 1;
  width: 100%;
  display: block;
  margin-bottom: 0;
  height: 26px;
  cursor: pointer;
}

.people-table-btn-group-control .people-table-btn-group-toggle .people-table-btn-group-toggle-icon {
  position: absolute;
  top: 3px;
  right: 3px;
  width: 20px;
  height: 20px;
  background-color: var(--status-color-dark);
  border-radius: 50%;
  z-index: 5;
  transition: all linear 0.25s
}

.people-table-btn-group-control .people-table-btn-group-toggle input {
  display: none;
}

.people-table-btn-group-control .people-table-btn-group-toggle input:checked ~ .people-table-btn-group-toggle-icon {
  background-color: #fff;
  transform: translateX(-64px);
}

.people-table-btn-group-control .people-table-btn-group-toggle input:checked ~ .people-table-btn-group-toggle-active {
  border-color: var(--status-color-green);
  background-color: var(--status-color-green);
  color: #fff;
}

.people-table-btn-group-control .people-table-btn-group-icon {
  border-radius: 3px;
  border: 1px solid var(--mainColor);
}

.people-table-comment-text-red {
  color: #dc3545;
}

.tr-additional-info {
  border-bottom: none !important;
}

.hiddenTrRow, .hiddenTdRow {
  line-height: 0 !important;
  padding: 0 !important;
  opacity: 0 !important;
}
.openTdRow {
  padding: 0 !important;
}
.openRow {
  opacity: 1;
  transition: all 0.5s;
  padding-left: 20px;
  padding-bottom: 16px;
}
.carRow {
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 24px;
  padding-right: 12px
}

.load_details_header {
  border-radius: 3px;
  border-width: 1px;
  border-color: dimgray;
  border-style: solid;
  text-align: center;
  padding: 7px;
  background-color: whitesmoke;
  color: black;
  margin-right: 10px;
  margin-bottom: 10px;
}

.load_details-btn {
  border-radius: 3px;
  border-width: 1px;
  border-style: solid;
  text-align: center;
  padding: 8px 12px 4px 12px;
  background: var(--mainColor);
  border-color: var(--mainColor);
  color: #fff;
  margin-right: 10px;
  margin-bottom: 10px;
  font-family: var(--font-myriadSemibold-regular);
  font-size: 14px;
}

.load_details-btn:hover {
  background: var(--mainColorHover);
  border-color: var(--mainColorHover);
  color: #fff;
  cursor: pointer;
}

.load_details-btn:active,
.load_details-btn:focus {
  background: var(--mainColorActive) !important;
  border-color: var(--mainColorActive) !important;
  color: #fff !important;
}

.car_detail_box {
  display: flex;
  flex-wrap: wrap;
}

.load_details_header:hover {
  background-color: gainsboro;
  cursor: pointer;
}

div.rowContainer {
  max-width: 100%;
  width: 100%;
  align-items: left;
  vertical-align: top;
  display: inline-block;
}

div.rowOuter {
  display: inline-block;
  padding-left: 0%;
  padding-right: 40px;
  padding-top: 10px;
  vertical-align: top;
}

div.rowOuter ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

div.rowOuter li:hover {
  cursor: pointer;
  /* text-shadow: 0 0 15px #c1c1c1, 0 0 10px #c1c1c1; */
  text-decoration: underline;
}

div.rowInner {
  display: inline-block;
  padding-left: 0%;
  padding-right: 40px;
  vertical-align: top;
  padding-top: 10px;
}

div.rowInner ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

div.rowInner li:hover {
  cursor: pointer;
  /* text-shadow: 0 0 15px #c1c1c1, 0 0 10px #c1c1c1; */
  text-decoration: underline;
}

div.rowLast {
  display: inline-block;
  padding-left: 0%;
  padding-right: 40px;
  vertical-align: top;
  padding-top: 10px;
}

div.rowLast ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

div.rowLast li:hover {
  cursor: pointer;
  /* text-shadow: 0 0 15px #c1c1c1, 0 0 10px #c1c1c1; */
  text-decoration: underline;
}

.rowTitle {
  font-weight: bold;
  color: lightslategray; 
  padding-left: 10px
}

.rowSubtitle {
  font-weight: bold;
  color: lightslategray; 
  cursor: auto !important;
  text-decoration: none !important;
  text-shadow: none !important;
}


.hiddenRow {
  width: 100%;
  height: 0 !important;
  opacity: 0 !important;
  transition: all 0.5s;
  line-height: 0 !important;
}

.td-is-open-load:hover {
  cursor: pointer;
}

.td-no-padding {
  padding: 0 !important;
}
.table-no-padding, .table-total-sum {
  border-top: 0 !important;
  margin-bottom: 0 !important;
}
.table-total-sum {
  margin-top: -24px;
  margin-bottom: 5px !important;
}
.people-table table.table-total-sum tr {
  border: none !important;
}
.custom-form-control-select-drivers {
  width: 200px;
  margin: 0 auto;
  margin-top: 0px;
}
.custom-form-control-select-drivers._filters {
  height: 26px;
  font-size: 12px;
}

.custom-form-control-select-drivers._filters .css-bg1rzq-control,
.custom-form-control-select-drivers._filters .css-1szy77t-control{
  min-height: 26px;
  height: 26px;
}

.custom-form-control-select-drivers._filters .css-1wy0on6,
.custom-form-control-select-drivers._filters .css-1hwfws3,
.custom-form-control-select-drivers._filters .css-1g6gooi
{
  height: 26px;
  line-height: 16px;
}

.filter-by-drivers-text {

}
.form-control-datepicker-range {
  display: block;
  height: calc(2.25rem + 2px);
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.form-control-datepicker-range._filters {
  display: block;
  height: 26px;
  padding: .375rem .75rem;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.datepicker-item {
  margin-right: 30px;
}

.people-table-header-filter {
  color: #5b626b;
  font-family: var(--font-medium);
  font-size: 12px;
}

.loads-table-header {
  list-style: none;
  display: flex;
  padding: 0;
  margin-bottom: 0;

}
.loads-table-header.header-first > li:nth-child(1) {
  margin-left: 10px;
}

.loads-table-header li {
  margin-right: 30px;
}

.loads-table-header.header-second li {
  margin-right: 15px;
}

.loads-table-header .form-control-datepicker-range {
  max-width: 120px;
}
.react-datepicker-popper {
  z-index: 10;
}
.dates-clean-up {
  position: absolute;
  margin: 8px 0 0 4px;
  padding-left: 120px;
}
.dates-clean-up:hover {
  color: var(--mainColor);
  cursor: pointer;
}
.fa-rotate-90 {
  transform: rotate(-90deg);
  transition: all .3s ease-in-out, all .3s ease-in-out;
}

.fa-rotate-0 {
  transform: rotate(0deg);
  transition: all .3s ease-in-out, all .3s ease-in-out;
}
.exportCSV:hover {
  cursor: pointer;
}
.exportHistoryCSV {
  justify-content: flex-end;
  display: flex;
  flex: 1 1;
  align-items: flex-end;
}

.table-pop-up {
    height: 400px;
    overflow: scroll;
}



.people-table-btn-group {
  display: flex;
  justify-content: center;
  border: 1px solid var(--mainColor);
  border-radius: 3px;
  /* width: 80px; */
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0 auto;
}

.send-invite-box {
  display: flex;
  justify-content: center;
  width: 80px;
  margin: 0 auto;
}

.people-table-btn-group-single {
  width: 26px;
}

.people-table-btn-group-icon:not(:last-child) {
  border-right: 1px solid var(--mainColor);
}

.trash-btn {
  border: 1px solid var(--mainColor);
}

.action-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.people-table-btn-group-icon {
  padding: 5px 6px;
  color: var(--mainColor);
  text-align: center;
  position: relative;
  z-index: 1;
  cursor: pointer;
}
.people-table-btn-group-icon:hover {
  color: var(--mainColorActive);
}

.people-table-btn-edit {
  font-size: 14px;
  color: #6e8195;
  cursor: pointer;
}

.people-table-btn-delete {
  color: #d33232;
  font-size: 14px;
  cursor: pointer;
}

.people-table-btn-edit-lock,
.people-table-btn-delete-lock {
  color: #c1c1c1;
  cursor: inherit;
}

.people-table-btn-edit-lock:hover,
.people-table-btn-delete-lock:hover {
  color: #c1c1c1;
}

.modal-body-delete {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-bottom: 60px;
}

.modal-body-delete button {
  margin: 0 80px;
  font-family: var(--font-myriadSemibold-regular);
}

.modal-body-delete-text {
  text-align: center;
  line-height: 36px;
  font-size: 28px;
  width: 100%;
  padding: 40px 0 20px 0;
}


.custom-pagination .page-item [aria-disabled="true"] {
  color: #ccc;
}


.people-table table td.data-warning {
  color: #dc3545;
}

.load-table-header {
  list-style: none;
  display: flex;
  padding: 0;
  margin-bottom: 0;
  width: 100%;
}

.load-table-header.header-first > li:nth-child(2) {
  margin-left: 30px;
}

.load-table-header li {
  margin-right: 30px;
}
.load-table-header li.exportCSV {
  margin-right: 0;
}

.load-table-header.header-second li {
  margin-right: 15px;
}
.delayed_checkbox {
  margin-right: 6px;
  margin-top: 6px;
}

.exportCSV {
  justify-content: flex-end;
  display: flex;
  flex: 1 1;
}
.exportCSV:hover {
  cursor: pointer;
}

.export-csv-image {
  height: 26px;
  margin-top: -3px;
}

.action-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border: 1px solid #5175A1;
}

.action-button.check {
  color: #7DD78B
}

.action-button.delete {
  color: #db5264
}

.action-button + .action-button {
  margin-left: 15px
}

.order-table {
  background-color: rgba(235, 235, 235, 0.4);
  border-top: none !important;
}

.table-border {
  border-bottom: 1px solid #5175A1;
}

.flex-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.car-counter {
  font-size: 11px;
  font-weight: 500;
  background: #B24AF1;
  border-radius: 50%;
  margin-left: 5px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 20px;
  max-width: 20px;
  min-height: 20px;
  max-height: 20px;
}

.action-icon {
  margin-left: 10px;
  cursor: pointer;
}

.order-table .table-head {
  display: flex;
  width: 100%;
  border-bottom: 1px solid #5175A1;
  padding: 10px 0 20px 20px;
}

.table-head div, .people-table-car-info-row div  {
  flex: 1 1;
  margin-right: 10px;
}

.table-head div {
  font-family: var(--font-medium);
  font-size: 12px;
}

.people-table-car-info-row {
  width: 100%;
  display: flex;
  padding: 6px 0 6px 20px;
  align-items: center;
  border-bottom: 1px solid #E7E9EC;
}
.people-table-car-info-row .no-logo {
  width: 42px;
  height: 42px;
  background: #E7E9EC;
  border-radius: 3px;
}

.people-table-car-info-row .logo {
  width: 42px;
  height: auto;
  background: #E7E9EC;
  border-radius: 3px;
}

.people-table-car-info-row .cell {
  color: #59B9DF;
}

.unique-flex {
  flex: 0.7 1 !important;
}

.current-company {
  flex: 1.5 1 !important;
}

.people-table-car-info-row .download-link > span {
  color: #59B9DF;
  text-decoration: underline;
  cursor: pointer;
}

.people-table-car-info-row .action-buttons-container {
  display: flex;
}

.action-buttons-container .action-button {
  border: 1px solid #5175A1;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #7DD78B;
  max-width: 25px;
  max-height: 25px;
  margin-right: 17px;
  border-radius: 3px;
  cursor: pointer;
}

.delete-button {
  color: #DB5264 !important;
  margin: 0 !important;
}

.warning {
  color: red !important;
}

.label-text {
  position: relative;
  font-family: var(--font-medium);
  color: #F50E0E;
  display: inline-block;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  top: 2px;
  left: -20px;
  font-style: italic;
  z-index: 1;
}

.map {
  height: 680px;
}

.loads-map {
  margin-top: 20px;
  padding: 20px;
  background-color: #fff;
  position: relative;
  z-index: 0;
}

.loads-map-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 20px;
}

.loads-map-header span {
  font-size: 14px;
  cursor: pointer;
  color: var(--mainColor)
}

.loads-map-title {
  color: #5b626b;
  font-weight: bold;
  font-size: 16px;
}
.loads-map .leaflet-container {
  height: 500px;
  width: 100%;
}

.custom-marker {
  font-size: 11px;
  width: 120px;
}

.custom-marker.driver {
  width: 200px !important;
}

.leaflet-popup-content {
  margin: 0 !important;
  padding: 15px 10px;
  width: auto !important;
}

.custom-marker-name {
  font-weight: bold;
  margin-bottom: 2px;
}

.custom-marker-code {
  margin-bottom: 2px;
}

.custom-marker-status {
  padding: 0 3px;
  color: #fff;
  border-radius: 3px;
  display: inline-block;
  margin-bottom: 2px;
}

.custom-marker-status-new {
  background-color: #58db83;
}

.custom-marker-status-picked {
  background-color: #6c757d;
}

.custom-marker-status-dispatched {
  background-color: #B24AF1;
}

.custom-marker-status-assigned {
  background-color: #f5b225;
}

.leaflet-popup-content {
  width: 110px !important;
}

.custom-marker-blue {
  margin-top: 2px;
  font-size: 13px;
  color: #0177ec;
}

.custom-marker-red {
  margin-top: 2px;
  font-size: 13px;
  color: #ff0000;
}

.car {
  float: right;
  font-weight: bold;
  font-size: 11px;
  color: #000000;
  padding-top: 3px;
}

#pac-container {
  padding-bottom: 12px;
  margin-right: 12px;
}

.pac-controls {
  display: inline-block;
  padding: 5px 11px;
}

.pac-controls label {
  font-family: Roboto;
  font-size: 13px;
  font-weight: 300;
}

#pac-input {
  background-color: #fff;
  font-family: Roboto;
  font-size: 15px;
  font-weight: 300;
  margin-left: 12px;
  padding: 2px 11px 2px 13px;
  text-overflow: ellipsis;
  width: 200px;
  margin-top: 16px;
  border: 1px solid;
}

#pac-input:focus {
  border-color: #4d90fe;
}

.label-map-priority {
  background-color: #73a5c2;
  border: 1px solid #638893;
  padding: 1px 4px;
  border-radius: 3px;
  color: #FFF;
}

.custom-marker .list {
  list-style: none;
  padding: 10px 0;
  margin-bottom: 15px;
  border-bottom: 1px solid #C4C4C4;
}

.custom-marker ul li {
  padding: 2px 0;
  font-weight: 600;
  font-size: 10px;
  line-height: 15px;
  color: #212224;
}

.custom-marker ul li > span {
  font-weight: 500;
  line-height: 15px;
  font-size: 10px;
  color: #212224;
  opacity: 0.5;
  margin-right: 5px;
}

.custom-marker .button {
  margin: 5px;
  cursor: pointer;
  height: 25px;
  width: 90px;
  border: 1px solid #5175A1;
  border-radius: 4px;
  background-color: #5175A1;
  color: white;
  font-weight: 700;
  line-height: 15px;
  font-size: 10px;
}

.custom-marker select {
  outline: #5175A1;
  background-color: #ffffff;
  margin-bottom: 5px;
  width: 100%;
  border: 1px solid #CFD4D9;
  border-radius: 4px;
  padding: 5px;
}

.custom-marker .button-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.custom-marker-name-blue {
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #5175A1;
}

.custom-marker-phone-blue {
  font-weight: 600;
  font-size: 12px;
  line-height: 21px;
  color: #5175A1;
}

.phone-blue {
  color: #5175A1 !important;
}

.buttons-container {
  padding-left: 50px;
}

.buttons-container button {
  background-color: #EBEBEB;
  color: #5175A1;
  border: 1px solid #EBEBEB
}

.custom-marker .secondary-button {
  background: none;
  border: none;
  color: #ED5A53;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  margin-left: 10px;
  padding: 0;
}

.disable {
  background: grey !important;
  border: none !important;
  opacity: .5;
}

.load {
  background-color: #fff;
  border-radius: 3px;
  box-shadow: 0.629px -0.777px 35px 0px rgba(0, 0, 0, 0.16);
  padding: 20px 30px 20px 30px;
}

.load .load-back {
  position: relative;
  z-index: 1;
  margin-bottom: 10px;
}

.load .load-back:before {
  font-size: 14px;
  margin-right: 10px;
}

.load  .form-group {
  display: flex;
  flex-wrap: wrap;
}

.load  .form-group .invalid-feedback {
  margin-left: 170px;
}

.load .form-group label {
  flex: 0 0 auto;
  width: 170px;
  font-size: 13px;
  color: #212224;
  margin-top: 5px;
}
.load  .form-group input,
.load  .load-new-model,
.load .form-group .form-control,
.load .form-group .select-wrap{
  width: calc(100% - 170px);
}

.load .react-datepicker-wrapper {
  width: calc(100% - 170px) !important;
}


.custom-form-group .form-group {
  width: calc((100% - 170px) / 4 - 10px );
  margin-right: 13px;
}

.custom-form-group .form-group:last-child {
  margin-right: 0;
}

.custom-form-group .form-group input {
  width: 100% !important;
}

.load .react-datepicker-wrapper input {
  width: 100% !important;
}

.load-new-model-header {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}

.load-new-model {
  width: 100%;
}

.load-new-model-form {
  display: none;
}

.load-new-model-form-active {
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  padding-top: 16px;
}

.load-new-model-form .btn {
  flex: 0 0 auto;
  margin-left: 50px;
  margin-bottom: 17px;
  padding-left: 30px;
  padding-right: 30px;
}

.load-new-model-form label {
  width: 100px !important;
}

.load-new-model-form .select-wrap {
  width: calc(100% - 100px) !important;
}

.load-new-model-checkbox-line {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 6px;
}

.load-new-model-checkbox-line  label {
  width: 77px !important;
  cursor: pointer;
}

.load-new-model-checkbox-line .custom-checkbox {
  padding-left: 0;
}

.load-new-model-checkbox-line .custom-control-label:before,
.load-new-model-checkbox-line .custom-control-label:after {
  left: inherit;
  right: 0;
  top: 0;
}

.load-status {
  padding: 4px 7px;
  text-transform: uppercase;
  border-radius: 3px;
  color: #fff;
  font-weight: bold;
  display: inline-block;
  font-size: 10px;
  line-height: 12px;
  margin-top: 7px;
}

.load-status-active {
  background-color: #58db83;
}

.load-status-disabled {
  background-color: #6c757d;
}

.load-status-alert {
  background-color: #f5b225;
}

.load-create-cancel {
  margin-right: 60px;
  display: inline-block;
  font-size: 14px;
  color: var(--mainColor);
  cursor: pointer;
  font-family: var(--font-myriadSemibold-regular);
}

.load-create-cancel:hover {
  text-decoration: underline;
}

.load-new-model-header-item {
  color: var(--mainColor);
  background-color: #f3f3f3;
  border-radius: 4px;
  display: inline-block;
  vertical-align: middle;
  border: 1px solid #b9c0c7;
  padding: 5px 45px 5px 15px;
  position: relative;
  z-index: 0;
  font-size: 12px;
  margin-right: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  font-family: var(--font-medium);
}

.load-new-model-header-item  div{
  position: relative;
  z-index: 5;
}

.load-new-model-add {
  font-family: var(--font-medium) !important;
  padding-left: 35px;
  padding-right: 35px;
  font-size: 12px !important;
}

.load-new-model-header-item:hover {
  box-shadow: 0 0 3px 0px #c5c5c5;
}

.load-new-model-form-active .custom-btn {
  font-size: 12px !important;
  font-family: var(--font-medium) !important;

}

.load-new-model-header-item span {
  width: 34px;
  height: 34px;
  position: absolute;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  top: 0;
  right: 0;
  border-left: 1px solid #b9c0c7;
  transition: all linear 0.2s;
  cursor: pointer;
  z-index: 5;
}

.load-new-model-header-item span:hover {
  background-color: #dedfe0;
}

.load-new-model-header-item span:before,
.load-new-model-header-item span:after {
  content: '';
  width: 14px;
  height: 1px;
  background-color: var(--mainColor);
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.load-new-model-header-item span:before {
  transform: rotate(-45deg);
}

.load-new-model-header-item span:after {
  transform: rotate(45deg);
}

.load-new-model-header-item-active {
  background-color: var(--mainColor);
  color: #fff;
}

.load-new-model-header-item-active span:before,
.load-new-model-header-item-active span:after {
  background-color: #fff;
}

.load-new-model-header-item-active span:hover {
  background-color: #28588c;
}

.load-new-model-header-item-error {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  margin: 0;
  padding: 0;
  border-radius: 3px;
  box-shadow: 0 0 0 0.1rem rgba(216, 15, 34, 0.46);
}

.load-custom-select,
.load-new-model-form .form-control {
  width: calc(100% - 100px) !important;
}

.load-custom-select-disabled {
  pointer-events: none;
  opacity: 0.45;
}

.load-select-drivers option:first-child {
  display: none;
}

.destinationPhone + .invalid-feedback{
  margin-top: -17px;
}


.custom-form-group .form-group .invalid-feedback {
  width: 100%;
  margin-left: 0 !important;
}

.custom-form-group .form-group input::placeholder {
  color: #c1c1c1;
}


.custom-control-label {
  cursor: pointer;
}

.form-control-error,
.form-control-select-error{
  border-color: #dc3545;
}

.custom-select-error-wrap.form-control-error > div {
  border-color: #dc3545;
}

.parsePDFerror {
  font-size: 12px;
  color: #dc3545;
}

.select-car-model-error {
  font-size: 14px;
  display: block;
  margin-top: 10px;
}


.load-new-model-form-info {
  font-size: 14px;
  margin-bottom: 0;
  margin-left: 30px;
}

.load-new-model-form-info:before {
  content: 'i';
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: var(--mainColor);
  color: #fff;
  text-align: center;
  line-height: 12px;
  font-size: 10px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
}


.load-new-model-form > .row {
  width: calc(100% - 50px - 112px);
}

.load-new-model-form-footer {
  display: flex;
  padding-top: 10px;
  flex-wrap: wrap;
}

.load-new-model-form-footer .custom-btn {
  margin: 0 !important;
}


.load-new-model-form-footer  .errorMsg {
  width: 100%;
}

.load-new-model-header-item {
    color: var(--mainColor);
    /* background-color: #f3f3f3; */
    border-radius: 4px;
    display: inline-block;
    vertical-align: middle;
    border: 1px solid #b9c0c7;
    padding: 5px 45px 5px 15px;
    position: relative;
    z-index: 0;
    font-size: 12px;
    margin-right: 10px;
    margin-bottom: 10px;
    cursor: pointer;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    /* line-height: 2.0; */
  }
  
  .load-new-model-header-item:hover {
    box-shadow: 0 0 3px 0px #c5c5c5;
  }
  
  .load-new-model-header-item span {
    width: 34px;
    height: 29px;
    position: absolute;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    top: 0;
    right: 0;
    border-left: 1px solid #b9c0c7;
    transition: all linear 0.2s;
    cursor: pointer;
  }
  
  .load-new-model-header-item span:hover {
    background-color: #dedfe0;
  }
  
  .load-new-model-header-item span:before,
  .load-new-model-header-item span:after {
    content: '';
    width: 14px;
    height: 1px;
    background-color: var(--mainColor);
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    margin: 0 auto;
  }
  
  .load-new-model-header-item span:before {
    transform: rotate(-45deg);
  }
  
  .load-new-model-header-item span:after {
    transform: rotate(45deg);
  }
  
  .load-new-model-header-item-active {
    background-color: var(--mainColor);
    color: #fff;
  }
  
  .load-new-model-header-item-active span:before,
  .load-new-model-header-item-active span:after {
    background-color: #fff;
  }
  
  .load-new-model-header-item-active span:hover {
    background-color: #28588c;
  ;
  }
  
  
  
  
  .load-new-file-header-item {
    color: var(--mainColor);
    background-color: #f3f3f3;
    border-radius: 4px;
    display: inline-block;
    vertical-align: middle;
    /* border: 1px solid #b9c0c7; */
    padding: 2px 30px 3px 15px;
    position: relative;
    z-index: 0;
    font-size: 12px;
    font-weight: 600;
    margin-right: 10px;
    margin-bottom: 10px;
    cursor: pointer;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    /* line-height: 2.0; */
  }
  
  .load-new-file-header-item:hover {
    box-shadow: 0px 0px 3px 0px #c5c5c5;
  }
  
  .load-new-file-header-item span {
    --mainColor: red;
    width: 29px;
    height: 100%;
    position: absolute;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    top: 0;
    right: 0;
    /* border-left: 1px solid #b9c0c7; */
    transition: all linear 0.2s;
    cursor: pointer;
  }
  
  .load-new-file-header-item span:hover {
    background-color: #dedfe0;
  }
  
  .load-new-file-header-item span:before,
  .load-new-file-header-item span:after {
    content: '';
    width: 14px;
    height: 1px;
    background-color: var(--mainColor);
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    margin: 0 auto;
  }
  
  .load-new-file-header-item span:before {
    transform: rotate(-45deg);
  }
  
  .load-new-file-header-item span:after {
    transform: rotate(45deg);
  }
  
  .load-new-file-header-item-active {
    background-color: var(--mainColor);
    color: #fff;
  }
  
  .load-new-file-header-item-active span:before,
  .load-new-file-header-item-active span:after {
    background-color: #fff;
  }
  
  .load-new-file-header-item-active span:hover {
    background-color: #28588c;
  ;
  }
  
.load {
  background-color: #fff;
  border-radius: 3px;
  box-shadow: 0.629px -0.777px 35px 0px rgba(0, 0, 0, 0.16);
  padding: 20px 30px 20px 30px;
}

.load .load-back {
  position: relative;
  z-index: 1;
  margin-bottom: 10px;
  font-family: var(--font-medium);
}

.load .load-back:before {
  font-size: 14px;
  margin-right: 10px;
}

.load  .form-group {
  display: flex;
  flex-wrap: wrap;
}

.load  .form-group .invalid-feedback {
  margin-left: 170px;
}

.load .form-group label {
  flex: 0 0 auto;
  width: 170px;
  font-size: 13px;
  color: #212224;
  margin-top: 5px;
}
.load  .form-group input,
.load  .load-new-model,
.load .form-group .form-control {
  width: calc(100% - 170px);
}

.load-new-model-header {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}

.load-new-model {
  width: 100%;
}

.load-new-model-form {
  display: none;
}

.load-new-model-form-active {
  display: flex;
  align-items: flex-end;
  padding-top: 16px;
}

.load-new-model-form .btn {
  flex: 0 0 auto;
  margin-left: 50px;
  margin-bottom: 17px;
  padding-left: 30px;
  padding-right: 30px;
}

.load-new-model-form label {
  width: 100px !important;
}

.load-new-model-checkbox-line {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 6px;
}

.load-new-model-checkbox-line  label {
  width: 77px !important;
  cursor: pointer;
}

.load-new-model-checkbox-line .custom-checkbox {
  padding-left: 0;
}

.load-new-model-checkbox-line .custom-control-label:before,
.load-new-model-checkbox-line .custom-control-label:after {
  left: inherit;
  right: 0;
  top: 0;
}


.load-status {
  padding: 4px 7px;
  text-transform: uppercase;
  border-radius: 3px;
  color: #fff;
  font-weight: bold;
  display: inline-block;
  font-size: 10px;
  line-height: 12px;
  margin-top: 7px;
}

.load-status-active {
  background-color: #58db83;
}

.load-status-disabled {
  background-color: #6c757d;
}

.load-status-alert {
  background-color: #f5b225;
}

.load-create-cancel {
  margin-right: 60px;
  display: inline-block;
  font-size: 14px;
  color: var(--mainColor);
  cursor: pointer;
}

.load-create-cancel:hover {
  text-decoration: underline;
}

.load-new-model-header-item {
  color: var(--mainColor);
  background-color: #f3f3f3;
  border-radius: 4px;
  display: inline-block;
  vertical-align: middle;
  border: 1px solid #b9c0c7;
  padding: 5px 45px 5px 15px;
  position: relative;
  z-index: 0;
  font-size: 12px;
  margin-right: 10px;
  margin-bottom: 10px;
  cursor: pointer;
}

.load-new-model-header-item:hover {
  box-shadow: 0 0 3px 0px #c5c5c5;
}

.load-new-model-header-item span {
  width: 34px;
  height: 34px;
  position: absolute;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  top: 0;
  right: 0;
  border-left: 1px solid #b9c0c7;
  transition: all linear 0.2s;
  cursor: pointer;
}

.load-new-model-header-item span:hover {
  background-color: #dedfe0;
}

.load-new-model-header-item span:before,
.load-new-model-header-item span:after {
  content: '';
  width: 14px;
  height: 1px;
  background-color: var(--mainColor);
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.load-new-model-header-item span:before {
  transform: rotate(-45deg);
}

.load-new-model-header-item span:after {
  transform: rotate(45deg);
}

.load-new-model-header-item-active {
  background-color: var(--mainColor);
  color: #fff;
}

.load-new-model-header-item-active span:before,
.load-new-model-header-item-active span:after {
  background-color: #fff;
}

.load-new-model-header-item-active span:hover {
  background-color: #28588c;
;
}

.settings-payments {
  border-radius: 4px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0.629px -0.777px 35px 0px rgba(0, 0, 0, 0.16);
  padding: 15px 30px;
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.settings-info {
  border-radius: 4px;
  padding: 15px 30px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0.629px -0.777px 35px 0px rgba(0, 0, 0, 0.16);
}

.settings-info .form-group label {
  font-size: 14px;
}

.settings-title {
  font-size: 18px;
  color: #5b626b;
  width: 100%;
  margin-bottom: 15px;
  font-family: var(--font-medium);
}

.settings-subtitle {
  font-size: 14px;
  color: #5b626b;
  width: 100%;
  margin-bottom: 15px;
  font-family: var(--font-medium);
}

.settings-payments-text {
  padding-right: 200px;
  width: calc(100% - 600px);
  font-size: 14px;
  color: #5b626b;
  margin-bottom: 0;
}

.settings-payments-text b {
  font-weight: bold;
}

.settings-payments-text span {
  font-size: 12px;
  cursor: pointer;
  color: var(--mainColor)
}

.settings-payments-text span:hover {
  text-decoration: underline;
}

.settings-info .form-group {
  display: flex;
  flex-wrap: wrap;
}

.settings-info .form-group label:not(.custom-control-label) {
  width: 180px;
  margin-top: 7px;
  text-align: right;
  padding-right: 20px;
}

.settings-info .custom-control {
  padding-left: 12px;
  padding-top: 7px;
}

.settings-info .custom-control .form-control{
  width: 100%;
}

.settings-info .custom-control-label:after,
.settings-info .custom-control-label:before {
  top: 2px;
}

.settings-info .form-control,
.settings-info .form-group input {
  width: calc(100% - 200px);
}

.settings-info .invalid-feedback {
  width: 100%;
  padding-left: 180px;
}

.settings-info .settings-info-card,
.settings-info .settings-info-email {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: space-between;
}

.settings-info .settings-info-email .form-group {
  width: calc(100% - 200px);
}

.settings-info-card-note {
  padding-top: 20px;
  font-size: 14px;
  max-width: 60%;
}

.settings-info-card-note:before,
.settings-info-email p:before {
  content: 'i';
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: var(--mainColor);
  color: #fff;
  text-align: center;
  line-height: 12px;
  font-size: 10px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
}

.settings-info-cancel {
  margin-right: 60px;
  display: inline-block;
  font-size: 14px;
  color: var(--mainColor);
  cursor: pointer;
  font-family: var(--font-myriadSemibold-regular);
}

.settings-info-cancel:hover {
  text-decoration: underline;
}

.settings-info-footer {
  padding-top: 25px;
  margin-top: 14px;
  border-top: 1px solid #b9bec3;
}

.settings-info-footer input:checked + label {
  opacity: 0.5;
}

.settings-info-email-note {
  margin-left: 180px;
  padding-left: 20px;
  width: calc(100% - 200px - 180px);
  position: relative;
  z-index: 1;
  font-size: 14px;
}

.settings-info-email-note:before {
  top: 7px;
  left: 0;
  position: absolute;
}

.settings-info-email,
.settings-info-card {
  border-bottom: 1px solid #ccc;
  margin-bottom: 40px;
  padding-bottom: 20px;
}

.settings-info-card-view {
  width: 300px;
  height: 180px;
  background: linear-gradient(var(--mainColor), #2b3a4a);
  margin-right: 50px;
  border-radius: 5px;
  font-size: 16px;
  color: #fff;
  text-align: center;
  position: relative;
  z-index: 1;
  padding: 10px 20px;
}


.settings-info-card-view img {
  width: 46px;
  height: 26px;
  position: absolute;
  top: 50px;
  left: 20px;
  display: block;
}


.settings-info-card-view-card-number {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 20px;
}

.settings-info-card-view-card-name {
  text-align: right;
}

.settings-info-card-view-card-number p {
  width: 100%;
  text-align: left;
  margin: 0;
  margin-bottom: 8px;
  font-size: 13px;
}

.settings-info-card-view-card-number span {
  letter-spacing: 1px;
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
}

.settings-info-card-new {
  width: calc(100% - 350px - 280px);
}

.settings-info-card-new form {
  display: flex;
  justify-content: space-between;
}

.settings-info-card-new .StripeElement {
  width: calc(100% - 200px);
  height: 36px;
  padding: 8px 12px;
}

.addressesForm {
  width: 100%;
}


.load {
  background-color: #fff;
  border-radius: 3px;
  box-shadow: 0.629px -0.777px 35px 0px rgba(0, 0, 0, 0.16);
  padding: 20px 30px 20px 30px;
}

.load .load-back {
  position: relative;
  z-index: 1;
  margin-bottom: 10px;
}

.load .load-back:before {
  font-size: 14px;
  margin-right: 10px;
}

.load  .form-group {
  display: flex;
  flex-wrap: wrap;
}

.load  .form-group .invalid-feedback {
  margin-left: 170px;
}

.load .form-group label {
  flex: 0 0 auto;
  width: 170px;
  font-size: 13px;
  color: #212224;
  margin-top: 5px;
}
.load  .form-group input,
.load  .load-new-model,
.load .form-group .form-control {
  width: calc(100% - 170px);
}

.load-new-model-header {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}

.load-new-model {
  width: 100%;
}

.load-new-model-form {
  display: none;
}

.load-new-model-form-active {
  display: flex;
  align-items: flex-end;
  padding-top: 16px;
}

.load-new-model-form .btn {
  flex: 0 0 auto;
  margin-left: 50px;
  margin-bottom: 17px;
  padding-left: 30px;
  padding-right: 30px;
}

.load-new-model-form label {
  width: 100px !important;
}

.load-new-model-checkbox-line {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 6px;
}

.load-new-model-checkbox-line  label {
  width: 77px !important;
  cursor: pointer;
}

.load-new-model-checkbox-line .custom-checkbox {
  padding-left: 0;
}

.load-new-model-checkbox-line .custom-control-label:before,
.load-new-model-checkbox-line .custom-control-label:after {
  left: inherit;
  right: 0;
  top: 0;
}


.load-status {
  padding: 4px 7px;
  text-transform: uppercase;
  border-radius: 3px;
  color: #fff;
  font-weight: bold;
  display: inline-block;
  font-size: 10px;
  line-height: 12px;
  margin-top: 7px;
}

.load-status-active {
  background-color: #58db83;
}

.load-status-disabled {
  background-color: #6c757d;
}

.load-status-alert {
  background-color: #f5b225;
}

.load-create-cancel {
  margin-right: 60px;
  display: inline-block;
  font-size: 14px;
  color: var(--mainColor);
  cursor: pointer;
}

.load-create-cancel:hover {
  text-decoration: underline;
}

.load-new-model-header-item {
  color: var(--mainColor);
  background-color: #f3f3f3;
  border-radius: 4px;
  display: inline-block;
  vertical-align: middle;
  border: 1px solid #b9c0c7;
  padding: 5px 45px 5px 15px;
  position: relative;
  z-index: 0;
  font-size: 12px;
  margin-right: 10px;
  margin-bottom: 10px;
  cursor: pointer;
}

.load-new-model-header-item:hover {
  box-shadow: 0 0 3px 0px #c5c5c5;
}

.load-new-model-header-item-view {
  padding-right: 15px;
}

.load-new-model-header-item-view:hover{
  box-shadow: none;
  cursor: inherit;
}

.load-new-model-header-item span {
  width: 34px;
  height: 34px;
  position: absolute;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  top: 0;
  right: 0;
  border-left: 1px solid #b9c0c7;
  transition: all linear 0.2s;
  cursor: pointer;
}

.load-new-model-header-item span:hover {
  background-color: #dedfe0;
}

.load-new-model-header-item span:before,
.load-new-model-header-item span:after {
  content: '';
  width: 14px;
  height: 1px;
  background-color: var(--mainColor);
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.load-new-model-header-item span:before {
  transform: rotate(-45deg);
}

.load-new-model-header-item span:after {
  transform: rotate(45deg);
}

.load-new-model-header-item-active {
  background-color: var(--mainColor);
  color: #fff;
}

.load-new-model-header-item-active span:before,
.load-new-model-header-item-active span:after {
  background-color: #fff;
}

.load-new-model-header-item-active span:hover {
  background-color: #28588c;
;
}

:root {
  --mainColor: #4875a5;
  --mainColorHover: #3b73af;
  --mainColorActive: #2b5582;

  --inputBackgroundColor: #f5f5f5;

  --sidebarBackground: #2b3a4a;
  --sidebarBackgroundHover: #263442;

  --color-blue: #4875a5;

  --cart-background-color-1: #5858b1;
  --cart-background-color-2: #4875a5;
  --cart-background-color-3: #28bbe3;

  --status-color-orange: #f5b225;
  --status-color-green: #58db83;
  --status-color-blue: #28bbe3;
  --status-color-dark: #6e8195;

  --font-bold:'Poppins-Bold',Arial, "Helvetica Neue", Helvetica, sans-serif;
  --font-semibold:'Poppins-SemiBold',Arial, "Helvetica Neue", Helvetica, sans-serif;
  --font-regular:'Poppins-Regular',Arial, "Helvetica Neue", Helvetica, sans-serif;
  --font-medium:'Poppins-medium',Arial, "Helvetica Neue", Helvetica, sans-serif;
  --font-myriad-regular: 'MyriadPro-Regular',Arial, "Helvetica Neue", Helvetica, sans-serif;
  --font-myriadSemibold-regular: 'MyriadPro-Semibold',Arial, "Helvetica Neue", Helvetica, sans-serif;

  --font-openSans-bold: 'MyriadPro-Semibold',Arial, "Helvetica Neue", Helvetica, sans-serif;
}

html, body {
  min-height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  font-size: 16px;
  line-height: 24px;
  font-family: var(--font-regular);
  overflow: inherit;
}

#root {
  background-color: #ebebeb;
}

.custom-btn {
  background: var(--mainColor);
  border-color: var(--mainColor);
  color: #fff;
  font-family: var(--font-myriadSemibold-regular);
}

.custom-btn:hover {
  background: var(--mainColorHover);
  border-color: var(--mainColorHover);
  color: #fff;
}

.custom-btn:active,
.custom-btn:focus {
  background: var(--mainColorActive) !important;
  border-color: var(--mainColorActive) !important;
  color: #fff !important;
}

.custom-btn-outline {
  background-color: #fff;
  border: 1px solid #ebebeb;
  color: #dc3545;
}

.custom-btn-outline:hover {
  border-color: #dc3545;
  color: #dc3545;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background: var(--mainColor);
  border-color: var(--mainColor);
}

.custom-checkbox-title {
  font-size: 13px;
  color: #212224;
}

.custom-btn-blocked,
.custom-btn-blocked:hover,
.custom-btn-blocked:focus,
.custom-btn-blocked:active {
  background-color: #6c757d !important;
  border-color: #6c757d !important;
}

a {
  color: var(--mainColor)
}

h1, h2, h3, h4, h5, h6 {
  font-family: var(--font-medium);
  font-weight: normal;
}

html,
.content-page {
  background-color: #ebebeb;
}

.form-control:focus {
  border-color: var(--mainColor)
}

.errorMsg {
  font-size: 12px;
  color: #dc3545;
  margin-bottom: 10px;
  display: block;
}

.breadcrumb li,
.breadcrumb a {
  font-size: 12px;
}

.form-control.is-invalid, .was-validated .form-control:invalid {
  background-image: none;
}

.custom-alert {
  color: #fff;
  background-color: var(--mainColor);
  padding: 5px 15px;
  border-radius: 3px;
}

.wrapper-page {
  max-width: 555px;
}


.page-title-box .page-title {
  font-weight: 500;
}


.breadcrumb .breadcrumb-item {
  font-family: var(--font-regular);
  font-size: 12px;
}

.custom-card-body h5 {
  font-size: 18px;
}


.card-body-title {
  font-size: 18px;
}

.btn-secondary {
  font-family: var(--font-myriadSemibold-regular);
}

.hide {
  display: none;
}

.noteitem {
  font-family: var(--font-regular);
  font-size: 12px;
  padding: 4px;
  color: #6c757d;
}


.custom-modal .modal-body,
.custom-modal .modal-header {
  padding-left: 30px;
  padding-right: 30px;
}

.custom-modal .form-group{
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.custom-modal .form-group input {
  width: calc(100% - 160px);
}

/*.custom-modal .form-group .invalid-feedback {*/
  /*text-align: right;*/
/*}*/

.custom-modal .custom-file p,
.custom-modal label:not(.btn){
  font-size: 13px;
  color: #212224;
  flex: 0 0 auto;
  width: 160px;
  line-height: 15px;
}

.custom-modal .custom-file {
  display: flex;
  align-items: center;
}

.custom-modal .custom-file-name {
  font-size: 14px;
  padding-left: 30px;
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.custom-modal .custom-file .btn {
  flex: 0 0 auto;
  font-family: var(--font-myriadSemibold-regular);
}

.custom-modal [type="file"] {
  display: none;
}

.custom-modal .custom-file label,
.custom-modal .custom-file p {
  margin-bottom: 0;
}

.custom-modal .custom-file label {
  line-height: 18px;
}

.custom-modal .custom-file label:before {
  content: "\F07C";
  font-family: 'Font Awesome 5 Free', Arial, "Helvetica Neue", Helvetica, sans-serif;
  display: inline-block;
  margin-right: 13px;
}

.custom-modal .modal-body-clients .invalid-feedback {
  width: 100%;
  text-align: left;
  padding-left: 160px;
}

.custom-modal .modal-title {
  font-size: 18px;
  font-family: var(--font-semibold);
}

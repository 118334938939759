.speech-bubble_postYourCar {
  position: absolute;
  transform-origin: 0% 100%;
  text-align: center;
  background-color: rgba(90, 169, 255, 0.9);
  color: white;
  border-radius: 10px;
  width: 40%;
  padding: 10px;
  left: 635px;
  top: 70px;
  animation-fill-mode: forwards;
  animation-duration: 0.25s;
  z-index: 888;
  max-width: 800px;
  margin: 10px;
  min-width: 256px;
}

.speech-bubble_findTransporter {
  position: absolute;
  transform-origin: 0% 100%;
  text-align: center;
  background-color: rgba(90, 169, 255, 0.9);
  color: white;
  border-radius: 10px;
  width: 80%;
  padding: 10px;
  left: 255px;
  top: 210px;
  animation-fill-mode: forwards;
  animation-duration: 0.25s;
  z-index: 1000;
  max-width: 800px;
}

.speech-bubble_findTransporter::before {
  content: "";
  display: block;
  width: 0;
  position: absolute;
  top: -15px;
  left: 55px;
  border-style: solid;
  border-width: 15px;
  border-color: rgba(90, 169, 255, 0.9) transparent transparent rgba(90, 169, 255, 0.9);
  transform: rotate(45deg);
  z-index: 888;
}

.speech-bubble_loadCreated {
  position: absolute;
  transform-origin: 0% 100%;
  text-align: center;
  background-color: rgba(90, 169, 255, 0.9);
  color: white;
  border-radius: 10px;
  width: 80%;
  padding: 10px;
  left: 255px;
  top: 425px;
  animation-fill-mode: forwards;
  animation-duration: 0.25s;
  z-index: 1000;
  max-width: 800px;
}

.speech-bubble_loadCreated::before {
  content: "";
  display: block;
  width: 0;
  position: absolute;
  top: -15px;
  left: 55px;
  border-style: solid;
  border-width: 15px;
  border-color: rgba(90, 169, 255, 0.9) transparent transparent rgba(90, 169, 255, 0.9);
  transform: rotate(45deg);
  z-index: 888;
}

.speech-bubble_showUploadPdf {
  position: absolute;
  transform-origin: 0% 100%;
  text-align: center;
  background-color: rgba(90, 169, 255, 0.9);
  color: white;
  border-radius: 10px;
  width: 80%;
  padding: 10px;
  left: 630px;
  top: 70px;
  animation-fill-mode: forwards;
  animation-duration: 0.25s;
  z-index: 888;
  max-width: 600px;
  margin: 10px;
  min-width: 256px;
}

.speech-bubble_showAdjustDates {
  position: absolute;
  transform-origin: 0% 100%;
  text-align: center;
  background-color: rgba(90, 169, 255, 0.9);
  color: white;
  border-radius: 10px;
  width: 40%;
  padding: 10px;
  left: 600px;
  top: 235px;
  animation-fill-mode: forwards;
  animation-duration: 0.25s;
  z-index: 888;
  max-width: 600px;
  margin: 10px;
  min-width: 256px;
}

.speech-bubble_showDesirableRate {
  position: absolute;
  transform-origin: 0% 100%;
  text-align: center;
  background-color: rgba(90, 169, 255, 0.9);
  color: white;
  border-radius: 10px;
  width: 40%;
  padding: 10px;
  left: 600px;
  top: 395px;
  animation-fill-mode: forwards;
  animation-duration: 0.25s;
  z-index: 888;
  max-width: 600px;
  margin: 10px;
  min-width: 256px;
}

.speech-bubble_showAddVehicle {
  position: absolute;
  transform-origin: 0% 100%;
  text-align: center;
  background-color: rgba(90, 169, 255, 0.9);
  color: white;
  border-radius: 10px;
  width: 40%;
  padding: 10px;
  left: 470px;
  top: 545px;
  animation-fill-mode: forwards;
  animation-duration: 0.25s;
  z-index: 888;
  max-width: 600px;
  margin: 10px;
  min-width: 256px;
}

.speech-bubble_showAttachExtras {
  position: absolute;
  transform-origin: 0% 100%;
  text-align: center;
  background-color: rgba(90, 169, 255, 0.9);
  color: white;
  border-radius: 10px;
  width: 40%;
  padding: 10px;
  left: 570px;
  top: 635px;
  animation-fill-mode: forwards;
  animation-duration: 0.25s;
  z-index: 888;
  max-width: 600px;
  margin: 10px;
  min-width: 256px;
}

.speech-bubble_showCorrectAddress {
  position: absolute;
  transform-origin: 0% 100%;
  text-align: center;
  background-color: rgba(90, 169, 255, 0.9);
  color: white;
  border-radius: 10px;
  width: 40%;
  padding: 10px;
  left: 570px;
  top: 1005px;
  animation-fill-mode: forwards;
  animation-duration: 0.25s;
  z-index: 888;
  max-width: 600px;
  margin: 10px;
  min-width: 256px;
}

.speech-bubble_showCorrectAddress::before {
  content: "";
  display: block;
  width: 0;
  position: absolute;
  top: 40px;
  left: -15px;
  border-style: solid;
  border-width: 15px;
  border-color: rgba(90, 169, 255, 0.9) transparent transparent rgba(90, 169, 255, 0.9);
  transform: rotate(315deg);
  z-index: 888;
}

.speech-bubble_showAddPaymentTerms {
  position: absolute;
  transform-origin: 0% 100%;
  text-align: center;
  background-color: rgba(90, 169, 255, 0.9);
  color: white;
  border-radius: 10px;
  width: 40%;
  padding: 10px;
  left: 570px;
  top: 1785px;
  animation-fill-mode: forwards;
  animation-duration: 0.25s;
  z-index: 888;
  max-width: 600px;
  margin: 10px;
  min-width: 256px;
}

.speech-bubble_showAddPaymentTerms::before {
  content: "";
  display: block;
  width: 0;
  position: absolute;
  top: 40px;
  left: -15px;
  border-style: solid;
  border-width: 15px;
  border-color: rgba(90, 169, 255, 0.9) transparent transparent rgba(90, 169, 255, 0.9);
  transform: rotate(315deg);
  z-index: 888;
}

.speech-bubble-expand {
  animation-name: "expand-bounce";
}

.speech-bubble-shrink {
  animation-name: "shrink";
}

.speech-bubble_postYourCar::before {
  content: "";
  display: block;
  width: 0;
  position: absolute;
  top: 30px;
  left: -15px;
  border-style: solid;
  border-width: 15px;
  border-color: rgba(90, 169, 255, 0.9) transparent transparent rgba(90, 169, 255, 0.9);
  transform: rotate(315deg);
  z-index: 888;
}

.speech-bubble_showUploadPdf::before {
  content: "";
  display: block;
  width: 0;
  position: absolute;
  top: 40px;
  left: -15px;
  border-style: solid;
  border-width: 15px;
  border-color: rgba(90, 169, 255, 0.9) transparent transparent rgba(90, 169, 255, 0.9);
  transform: rotate(315deg);
  z-index: 888;
}

.speech-bubble_showAdjustDates::before {
  content: "";
  display: block;
  width: 0;
  position: absolute;
  top: 40px;
  left: -15px;
  border-style: solid;
  border-width: 15px;
  border-color: rgba(90, 169, 255, 0.9) transparent transparent rgba(90, 169, 255, 0.9);
  transform: rotate(315deg);
  z-index: 888;
}

.speech-bubble_showDesirableRate::before {
  content: "";
  display: block;
  width: 0;
  position: absolute;
  top: 40px;
  left: -15px;
  border-style: solid;
  border-width: 15px;
  border-color: rgba(90, 169, 255, 0.9) transparent transparent rgba(90, 169, 255, 0.9);
  transform: rotate(315deg);
  z-index: 888;
}

.speech-bubble_showAttachExtras::before {
  content: "";
  display: block;
  width: 0;
  position: absolute;
  top: 40px;
  left: -15px;
  border-style: solid;
  border-width: 15px;
  border-color: rgba(90, 169, 255, 0.9) transparent transparent rgba(90, 169, 255, 0.9);
  transform: rotate(315deg);
  z-index: 888;
}

.speech-bubble_showAddVehicle::before {
  content: "";
  display: block;
  width: 0;
  position: absolute;
  top: -15px;
  left: 35px;
  border-style: solid;
  border-width: 15px;
  border-color: rgba(90, 169, 255, 0.9) transparent transparent rgba(90, 169, 255, 0.9);
  transform: rotate(45deg);
  z-index: 888;
}

@media (max-width: 1436px) {
  .speech-bubble_postYourCar {
    width: auto;
    top: 160px;
    left: 85px;
    background-color: rgba(90, 169, 255, 0.9);
  }

  .speech-bubble_postYourCar::before {
    top: -15px;
    left: 265px;
    transform: rotate(45deg);
  }

  .speech-bubble_findTransporter {
    left: 85px;
  }

  .speech-bubble_loadCreated {
    left: 85px;
  }

  .speech-bubble_showUploadPdf {
    width: auto;
    left: 185px;
    top: 165px;
    background-color: rgba(90, 169, 255, 0.9);
  }
  .speech-bubble_showUploadPdf::before {
    top: -15px;
    left: 175px;
    transform: rotate(45deg);
  }
  .speech-bubble_showAdjustDates {
    width: auto;
    left: 85px;
    top: 385px;
    background-color: rgba(90, 169, 255, 0.9);
  }
  .speech-bubble_showAdjustDates::before {
    top: -15px;
    left: 55px;
    transform: rotate(45deg);
  }

  .speech-bubble_showDesirableRate {
    width: auto;
    left: 85px;
    top: 485px;
    background-color: rgba(90, 169, 255, 0.9);
  }
  .speech-bubble_showDesirableRate::before {
    top: -15px;
    left: 55px;
    transform: rotate(45deg);
  }

  .speech-bubble_showAttachExtras {
    left: 275px;
    top: 730px;
    width: auto; 
    background-color: rgba(90, 169, 255, 0.9);
  }
  .speech-bubble_showAttachExtras::before {
    top: -15px;
    left: 35px;
    transform: rotate(45deg);
  }

  .speech-bubble_showAddVehicle {
    width: auto;
    left: 85px;
    background-color: rgba(90, 169, 255, 0.9);
  }
  .speech-bubble_showAddVehicle::before {
    top: -15px;
    left: 55px;
    transform: rotate(45deg);
  }

  .speech-bubble_showCorrectAddress {
    left: 270px;
    top: 1105px;
    width: auto; 
    background-color: rgba(90, 169, 255, 0.9);
  }
  .speech-bubble_showCorrectAddress::before {
    top: -15px;
    left: 35px;
    transform: rotate(45deg);
  }

  .speech-bubble_showAddPaymentTerms {
    left: 270px;
    top: 1885px;
    width: auto; 
    background-color: rgba(90, 169, 255, 0.9);
  }
  .speech-bubble_showAddPaymentTerms::before {
    top: -15px;
    left: 35px;
    transform: rotate(45deg);
  }
}

@media (max-width: 480px) {
  .speech-bubble_postYourCar {
    left: 85px;
    background-color: rgba(90, 169, 255, 0.9);
    margin-right: auto;
    width: 75%;
  }

  .speech-bubble_findTransporter {
    left: 85px;
  }

  .speech-bubble_loadCreated {
    left: 85px;
  }

  .speech-bubble_showUploadPdf {
    width: 70%;
    left: 105px;
    top: 165px;
    margin-right: auto;
    background-color: rgba(90, 169, 255, 0.9);
  }
  .speech-bubble_showUploadPdf::before {
    top: -15px;
    left: 235px;
    transform: rotate(45deg);
  }
  .speech-bubble_showAdjustDates {
    width: 60%;
    left: 85px;
    top: 385px;
    margin-right: auto;
    background-color: rgba(90, 169, 255, 0.9);
  }
  .speech-bubble_showDesirableRate {
    left: 85px;
    background-color: rgba(90, 169, 255, 0.9);
  }
  .speech-bubble_showAttachExtras {
    width: 75%;
    left: 85px;
    margin-right: auto;
    background-color: rgba(90, 169, 255, 0.9);
  }
  .speech-bubble_showAddVehicle {
    left: 85px;
    margin-right: auto;
    background-color: rgba(90, 169, 255, 0.9);
  }

  .speech-bubble_showCorrectAddress {
    width: 75%;
    left: 85px;
    margin-right: auto;
    background-color: rgba(90, 169, 255, 0.9);
  }

  .speech-bubble_showCorrectAddress::before {
    top: -15px;
    left: 215px;
    transform: rotate(45deg);
  }

  .speech-bubble_showAddPaymentTerms {
    width: 75%;
    left: 85px;
    margin-right: auto;
    background-color: rgba(90, 169, 255, 0.9);
  }
  .speech-bubble_showAddPaymentTerms::before {
    top: -15px;
    left: 65px;
    transform: rotate(45deg);
  }
}

@media (max-width: 419px) {
  .speech-bubble_postYourCar {
    width: 90%;
    top: 160px;
    left: 15px;
    margin: auto;
    background-color: rgba(90, 169, 255, 0.9);
  }

  .speech-bubble_findTransporter {
    left: 15px;
    width: 90%;
  }

  .speech-bubble_loadCreated {
    left: 15px;
    width: 90%;
  }

  .speech-bubble_showUploadPdf {
    width: 90%;
    top: 175px;
    left: 15px;
    margin: auto;
    background-color: rgba(90, 169, 255, 0.9);
  }

  .speech-bubble_showAdjustDates {
    width: 90%;
    left: 15px;
    top: 395px;
    margin: auto;
    background-color: rgba(90, 169, 255, 0.9);
  }

  .speech-bubble_showDesirableRate {
    width: 90%;
    left: 15px;
    top: 485px;
    margin: auto;
    background-color: rgba(90, 169, 255, 0.9);
  }

  .speech-bubble_showAttachExtras {
    width: 90%;
    top: 740px;
    left: 15px;
    margin: auto;
    background-color: rgba(90, 169, 255, 0.9);
  }

  .speech-bubble_showAddVehicle {
    width: 90%;
    left: 15px;
    margin: auto;
    top: 555px;
    background-color: rgba(90, 169, 255, 0.9);
  }

  .speech-bubble_showCorrectAddress {
    width: 90%;
    left: 15px;
    margin: auto;
    top: 1105px;
    background-color: rgba(90, 169, 255, 0.9);
  }

  .speech-bubble_showAddPaymentTerms {
    width: 90%;
    left: 15px;
    margin: auto;
    top: 1895px;
    background-color: rgba(90, 169, 255, 0.9);
  }

  .speech-bubble_postYourCar::before {
    scale: 0;
  }
  
   .speech-bubble_showUploadPdf::before {
    scale: 0;
  }
}

@media (pointer:none), (pointer:coarse) {
  .speech-bubble_postYourCar {
    left: 85px;
    background-color: rgba(90, 169, 255, 0.9);
  }
  .speech-bubble_showUploadPdf {
    left: 85px;
    background-color: rgba(90, 169, 255, 0.9);
  }
  .speech-bubble_showAdjustDates {
    left: 85px;
    background-color: rgba(90, 169, 255, 0.9);
  }
  .speech-bubble_showDesirableRate {
    left: 85px;
    background-color: rgba(90, 169, 255, 0.9);
  }
  .speech-bubble_showAttachExtras {
    left: 85px;
    background-color: rgba(90, 169, 255, 0.9);
  }
  .speech-bubble_showAddVehicle {
    left: 85px;
    background-color: rgba(90, 169, 255, 0.9);
  }
}

@keyframes expand-bounce {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(0.5);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes shrink {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.5);
  }
  100% {
    transform: scale(0);
  }
}

.button-speech-bubble {
  border: none;
  position: relative;
  float: right;
  color: #525f80;
  font-size: 24px;
  background-color: rgba(0, 0, 0, 0);
}

.button-speech-bubble:hover {
  cursor: pointer;
}

.speech-link {
  text-decoration: none;
  color: #525f80;
}

.youtube-speech-bubble {
  border: none;
  position: relative;
  vertical-align:middle;
  /* float: right; */
  color: #525f80;
  /* font-size: 24px; */
  background-color: rgba(0, 0, 0, 0);
}

.youtube-speech-bubble:hover {
  cursor: pointer;
}

.howtouse-speech-bubble {
  border: none;
  position: relative;
  vertical-align:middle;
  color: #525f80;
  background-color: rgba(0, 0, 0, 0);
}

.howtouse-speech-bubble:hover {
  cursor: pointer;
}

.speech-text {
  color: white;
  text-align: left;
}

.speech-title {
  color: white;
  padding-bottom: 30px;
  text-align: center;
}

.speech-content {
  margin: 20px 20px 20px 20px
}
.topbar .topbar-left,
.topbar .navbar-custom,
.topbar .button-menu-mobile {
  background-color: #fff;
}

.logo span img {
  vertical-align: baseline;
}

.topbar .topbar-left {
  border-right: 1px solid #c2c8d9
}

.navbar-custom {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  box-shadow: 0.669px 0.743px 6px 0 rgba(0, 0, 0, 0.23);
}

.navbar-custom ul {
  display: flex;
  align-items: center;
}

.app-search .form-control, .app-search .form-control:focus {
  background-color: #f8f9fa;
  border: 1px solid #e9ecef;
}

.side-menu {
  background-color: var(--sidebarBackground);
}

#sidebar-menu {
  padding-top: 30px;
}

#sidebar-menu > ul > li > a.active,
#sidebar-menu > ul > li > a:hover {
  background-color: var(--sidebarBackgroundHover);
  color: #fff !important;
}

.notification-list .nav-link {
  line-height: 50px;
}

.notification-list .noti-icon-badge {
  display: inline-block;
  position: absolute;
  top: 10px;
  right: 5px;
  padding-left: 6px;
  padding-right: 6px;
  line-height: 10px;
}


#wrapper {
  height: auto;
  min-height: 100% !important;
}

#sidebar-menu {
  padding-top: 0;
}

.metismenu {

  margin: 0;
}

.metismenu > li {
  max-height: 52px;
  line-height: 0;
}

.metismenu > li a {
  line-height: 24px;
}


.topbar .dropdown-item,
.topbar .dropdown-menu {
  background: #f8f9fa;
}


.dropdown-item.notify-item {
  background-color: #fff;
  position: relative;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 5px;
  width: calc( 100% - 10px);
}


.dropdown-item-close {
  position: absolute !important;
  top: 5px;
  right: 5px;
  cursor: pointer;
  width: 16px;
  height: 16px;
  text-align: center;
  line-height: 16px;
  display: block;
  transition: all linear 0.2s;
  border: 1px solid transparent;
  border-radius: 3px;
}

.dropdown-item-close:hover {
  color: var(--mainColor);
  border-color: var(--mainColor);
}

.dropdown-item-close:before{
  content: "\F156";
  font: normal normal normal 14px/1 "Material Design Icons"
}

.custom-btn-upgrade {
  font-family: var(--font-myriad-regular);
  white-space: nowrap;
}

.dropdown-icon {
  display: inline-block;
  margin-left: 5px;
  position: relative;
  z-index: 1;
  width: 10px;
  height: 10px;
}

.dropdown-icon:before {
  content: '';
  display: block;
  position: absolute;
  top: 3px;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 5px solid #000;
}

.main-content-header-right label.custom-btn {
  margin-bottom: 0;
  position: relative;
  z-index: 1;
}

.main-content-header-right label.custom-btn input{
  display: none;
}

.custom-spinner {
  width: 20px;
  height: 20px;
  border: 2px solid var(--mainColorHover);
  border-right-color: transparent;
  animation: spinner-border .75s linear infinite;
  border-radius: 50%;
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
  margin-bottom: 5px;
}


.topbar-messageLimit {
  font-size: 12px;
  padding: 11px 30px;
  padding-left: 60px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
  z-index: 1;
  border-left: 1px solid #c2c8d9;
  width: 625px;
}

.topbar-messageLimit span {
  position: absolute;
  top: 50%;
  left: 20px;
  margin-top: -10px;
  font-size: 18px;
  color: #f5b225;
}

.topbar-list li {
  flex: 0 0 auto;
}


.topbar-list li:last-child {
  width: 100%;
}


.topbar .navbar-custom .navbar-right {
  flex: 0 0 auto
}

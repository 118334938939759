.custom-login {
  border: 1px solid #c2c8d9;
  border-radius: 0;
  padding-top: 28px;
  padding-bottom: 20px;
}

.custom-login form {
  margin-top: 20px;
}

.custom-login-header {
  text-align: center;
  position: relative;
  z-index: 1;
}

.custom-login-header .logo {
  position: static;
  margin: 0 auto;
  width: 91px;
  height: 68px;
  display: block;
  border: none;
  border-radius: 0;
  line-height: 0;
  margin-bottom: 28px;

}

.custom-login-header h4 {
  text-transform: uppercase;
  font-size: 18px;
  line-height: 22px;
  margin: 0;
}

.custom-login-header p {
  font-size: 14px;
}


.custom-login .account-card-content {
  padding: 0px 22px 10px 22px;
}


.custom-login input {
  background-color: var(--inputBackgroundColor);
}

.custom-login .custom-control-label:before {
  background-color: var(--inputBackgroundColor);
  top: 0.15rem;
}

.custom-login  .custom-control-label::after {
  top: 0.15rem;
}

.custom-login-msg {
  font-size: 18px;
  color: var(--mainColor);
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.custom-login input::placeholder {
  font-size: 12px;
  color: #b2b5b9;
}

.custom-login-text {
  font-family: var(--font-myriad-regular);
  font-size: 14px;
}

.custom-login .form-group label {
  font-size: 14px;
}

.custom-login .custom-control.custom-checkbox label {
  font-size: 14px;
}

.custom-login .form-group a {
  font-size: 14px;
}


.custom-login  [type='submit'] {
  font-family: var(--font-myriadSemibold-regular);
  font-size: 14px;
}

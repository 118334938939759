.map {
  height: 680px;
}

.loads-map {
  margin-top: 20px;
  padding: 20px;
  background-color: #fff;
  position: relative;
  z-index: 0;
}

.loads-map-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 20px;
}

.loads-map-header span {
  font-size: 14px;
  cursor: pointer;
  color: var(--mainColor)
}

.loads-map-title {
  color: #5b626b;
  font-weight: bold;
  font-size: 16px;
}
.loads-map .leaflet-container {
  height: 500px;
  width: 100%;
}

.custom-marker {
  font-size: 11px;
  width: 120px;
}

.custom-marker.driver {
  width: 200px !important;
}

.leaflet-popup-content {
  margin: 0 !important;
  padding: 15px 10px;
  width: auto !important;
}

.custom-marker-name {
  font-weight: bold;
  margin-bottom: 2px;
}

.custom-marker-code {
  margin-bottom: 2px;
}

.custom-marker-status {
  padding: 0 3px;
  color: #fff;
  border-radius: 3px;
  display: inline-block;
  margin-bottom: 2px;
}

.custom-marker-status-new {
  background-color: #58db83;
}

.custom-marker-status-picked {
  background-color: #6c757d;
}

.custom-marker-status-dispatched {
  background-color: #B24AF1;
}

.custom-marker-status-assigned {
  background-color: #f5b225;
}

.leaflet-popup-content {
  width: 110px !important;
}

.custom-marker-blue {
  margin-top: 2px;
  font-size: 13px;
  color: #0177ec;
}

.custom-marker-red {
  margin-top: 2px;
  font-size: 13px;
  color: #ff0000;
}

.car {
  float: right;
  font-weight: bold;
  font-size: 11px;
  color: #000000;
  padding-top: 3px;
}

#pac-container {
  padding-bottom: 12px;
  margin-right: 12px;
}

.pac-controls {
  display: inline-block;
  padding: 5px 11px;
}

.pac-controls label {
  font-family: Roboto;
  font-size: 13px;
  font-weight: 300;
}

#pac-input {
  background-color: #fff;
  font-family: Roboto;
  font-size: 15px;
  font-weight: 300;
  margin-left: 12px;
  padding: 2px 11px 2px 13px;
  text-overflow: ellipsis;
  width: 200px;
  margin-top: 16px;
  border: 1px solid;
}

#pac-input:focus {
  border-color: #4d90fe;
}

.label-map-priority {
  background-color: #73a5c2;
  border: 1px solid #638893;
  padding: 1px 4px;
  border-radius: 3px;
  color: #FFF;
}

.custom-marker .list {
  list-style: none;
  padding: 10px 0;
  margin-bottom: 15px;
  border-bottom: 1px solid #C4C4C4;
}

.custom-marker ul li {
  padding: 2px 0;
  font-weight: 600;
  font-size: 10px;
  line-height: 15px;
  color: #212224;
}

.custom-marker ul li > span {
  font-weight: 500;
  line-height: 15px;
  font-size: 10px;
  color: #212224;
  opacity: 0.5;
  margin-right: 5px;
}

.custom-marker .button {
  margin: 5px;
  cursor: pointer;
  height: 25px;
  width: 90px;
  border: 1px solid #5175A1;
  border-radius: 4px;
  background-color: #5175A1;
  color: white;
  font-weight: 700;
  line-height: 15px;
  font-size: 10px;
}

.custom-marker select {
  outline: #5175A1;
  background-color: #ffffff;
  margin-bottom: 5px;
  width: 100%;
  border: 1px solid #CFD4D9;
  border-radius: 4px;
  padding: 5px;
}

.custom-marker .button-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.custom-marker-name-blue {
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #5175A1;
}

.custom-marker-phone-blue {
  font-weight: 600;
  font-size: 12px;
  line-height: 21px;
  color: #5175A1;
}

.phone-blue {
  color: #5175A1 !important;
}

.buttons-container {
  padding-left: 50px;
}

.buttons-container button {
  background-color: #EBEBEB;
  color: #5175A1;
  border: 1px solid #EBEBEB
}

.custom-marker .secondary-button {
  background: none;
  border: none;
  color: #ED5A53;
  max-width: fit-content;
  margin-left: 10px;
  padding: 0;
}

.disable {
  background: grey !important;
  border: none !important;
  opacity: .5;
}

.settings-payments {
  border-radius: 4px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0.629px -0.777px 35px 0px rgba(0, 0, 0, 0.16);
  padding: 15px 30px;
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.settings-info {
  border-radius: 4px;
  padding: 15px 30px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0.629px -0.777px 35px 0px rgba(0, 0, 0, 0.16);
}

.settings-info .form-group label {
  font-size: 14px;
}

.settings-title {
  font-size: 18px;
  color: #5b626b;
  width: 100%;
  margin-bottom: 15px;
  font-family: var(--font-medium);
}

.settings-subtitle {
  font-size: 14px;
  color: #5b626b;
  width: 100%;
  margin-bottom: 15px;
  font-family: var(--font-medium);
}

.settings-payments-text {
  padding-right: 200px;
  width: calc(100% - 600px);
  font-size: 14px;
  color: #5b626b;
  margin-bottom: 0;
}

.settings-payments-text b {
  font-weight: bold;
}

.settings-payments-text span {
  font-size: 12px;
  cursor: pointer;
  color: var(--mainColor)
}

.settings-payments-text span:hover {
  text-decoration: underline;
}

.settings-info .form-group {
  display: flex;
  flex-wrap: wrap;
}

.settings-info .form-group label:not(.custom-control-label) {
  width: 180px;
  margin-top: 7px;
  text-align: right;
  padding-right: 20px;
}

.settings-info .custom-control {
  padding-left: 12px;
  padding-top: 7px;
}

.settings-info .custom-control .form-control{
  width: 100%;
}

.settings-info .custom-control-label:after,
.settings-info .custom-control-label:before {
  top: 2px;
}

.settings-info .form-control,
.settings-info .form-group input {
  width: calc(100% - 200px);
}

.settings-info .invalid-feedback {
  width: 100%;
  padding-left: 180px;
}

.settings-info .settings-info-card,
.settings-info .settings-info-email {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: space-between;
}

.settings-info .settings-info-email .form-group {
  width: calc(100% - 200px);
}

.settings-info-card-note {
  padding-top: 20px;
  font-size: 14px;
  max-width: 60%;
}

.settings-info-card-note:before,
.settings-info-email p:before {
  content: 'i';
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: var(--mainColor);
  color: #fff;
  text-align: center;
  line-height: 12px;
  font-size: 10px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
}

.settings-info-cancel {
  margin-right: 60px;
  display: inline-block;
  font-size: 14px;
  color: var(--mainColor);
  cursor: pointer;
  font-family: var(--font-myriadSemibold-regular);
}

.settings-info-cancel:hover {
  text-decoration: underline;
}

.settings-info-footer {
  padding-top: 25px;
  margin-top: 14px;
  border-top: 1px solid #b9bec3;
}

.settings-info-footer input:checked + label {
  opacity: 0.5;
}

.settings-info-email-note {
  margin-left: 180px;
  padding-left: 20px;
  width: calc(100% - 200px - 180px);
  position: relative;
  z-index: 1;
  font-size: 14px;
}

.settings-info-email-note:before {
  top: 7px;
  left: 0;
  position: absolute;
}

.settings-info-email,
.settings-info-card {
  border-bottom: 1px solid #ccc;
  margin-bottom: 40px;
  padding-bottom: 20px;
}

.settings-info-card-view {
  width: 300px;
  height: 180px;
  background: linear-gradient(var(--mainColor), #2b3a4a);
  margin-right: 50px;
  border-radius: 5px;
  font-size: 16px;
  color: #fff;
  text-align: center;
  position: relative;
  z-index: 1;
  padding: 10px 20px;
}


.settings-info-card-view img {
  width: 46px;
  height: 26px;
  position: absolute;
  top: 50px;
  left: 20px;
  display: block;
}


.settings-info-card-view-card-number {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 20px;
}

.settings-info-card-view-card-name {
  text-align: right;
}

.settings-info-card-view-card-number p {
  width: 100%;
  text-align: left;
  margin: 0;
  margin-bottom: 8px;
  font-size: 13px;
}

.settings-info-card-view-card-number span {
  letter-spacing: 1px;
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
}

.settings-info-card-new {
  width: calc(100% - 350px - 280px);
}

.settings-info-card-new form {
  display: flex;
  justify-content: space-between;
}

.settings-info-card-new .StripeElement {
  width: calc(100% - 200px);
  height: 36px;
  padding: 8px 12px;
}

.addressesForm {
  width: 100%;
}

